import { SimpleGrid, VStack, Text, HStack, Button } from "@chakra-ui/react";
import { BigNumber, ethers } from "ethers";
import { useCallback, useState } from "react";
import { IHistoryItem } from "~/dto/pool-fixed.dto";

const ItemRowInfo = (props: {
    valueConfigs: {
        value: string | number;
        color?: string;
    }[], color?: string
}) => {
    const { valueConfigs } = props;
    return (
        <VStack
            w="full"
            alignItems="start"
        >
            <HStack
                w="full"
                alignItems="start"
            >
                {valueConfigs.map(({ value, color = "#fff" }) => {
                    return (
                        <Text
                            fontWeight="700"
                            fontSize="15px"
                            lineHeight="19px"
                            color={color}
                        >
                            {value}
                        </Text>
                    )
                })}
            </HStack>

        </VStack>
    )
}





interface IProps extends IHistoryItem {
    withdraw: (orderId: number) => Promise<void>
}

export const HistoryItem = (props: IProps) => {
    const {
        orderId = 0,
        amount = "0",
        startTimeLock = "",
        endTimeLock = "",
        packageTitle = "",
        endTimeLockBig = ethers.constants.MaxUint256,
        hasWithdraw = false,
        withdraw
    } = props;
    const [isLoading, setLoading] = useState(false);

    const grantedWithdraw = endTimeLockBig.toNumber() < Math.floor(new Date().getTime() / 1000);

    const onWithraw = useCallback(async () => {
        setLoading(true);
        await withdraw(orderId)
        setLoading(false);
    }, [orderId, withdraw]);


    const renderAction = useCallback(() => {
        if (!hasWithdraw) {
            return <Button
                w="full"
                border="1px solid #FF1CF7"
                background="transparent"
                _focus={{
                    backgroundColor: "transparent",
                }}
                _hover={{
                    backgroundColor: "transparent",
                }}
                borderRadius="5px"
                color="#fff"
                disabled={!grantedWithdraw}
                isLoading={isLoading}
                onClick={onWithraw}
            >
                Withdraw
            </Button>
        }
        return (
            <Text
                w={"full"}
                fontWeight="400"
                fontSize="20px"
                lineHeight="16px"
                color="gray"
                p={"10px"}
            >
                Has withdraw
            </Text>
        )
    }, [grantedWithdraw, hasWithdraw, isLoading, onWithraw])


    return (
        <HStack
            w="full"
            p="5px"
            _hover={{
                borderColor: "primary",
                background: "linear-gradient(126.12deg, rgba(255, 0, 255, 0.2) -1.57%, rgba(255, 28, 247, 0) 109.63%)",
            }}
            borderColor={{ base: "primary", md: "transparent" }}
            background={{ base: "linear-gradient(126.12deg, rgba(255, 0, 255, 0.2) -1.57%, rgba(255, 28, 247, 0) 109.63%)", md: "transparent" }}
        >
            <ItemRowInfo valueConfigs={[{ value: packageTitle, color: "primary" }]} />
            <ItemRowInfo valueConfigs={[{ value: amount }, { value: " $SHARKIE", color: "primary" }]} />
            <ItemRowInfo valueConfigs={[{ value: startTimeLock }]} />
            <ItemRowInfo valueConfigs={[{ value: endTimeLock }]} />
            <VStack
                w="full"
                alignItems="start"
            >
                {renderAction()}
            </VStack>
        </HStack>
    )
}