
import { Box, Button, HStack, Icon, Input, InputGroup, InputRightElement, SimpleGrid, Stack } from '@chakra-ui/react'
import { ReactComponent as RefreshIcon } from '~/assets/svgs/refresh.svg'
import { ReactComponent as SearchIcon } from '~/assets/svgs/search.svg'
import Loading from '~/components/Loading';
import PaginationComponent from '~/components/Pagination';
import Select from '~/components/Select';
import { useMarket, useMyOrder } from '~/hooks/market'
import { MarketItem } from './MarketItem';

const ORDERS = {
    data: {
        newest: 'newest',
        oldest: 'oldest',
        price_low_to_high: 'Price: Low to high',
        price_high_to_low: 'Price: High to low',
    },
    ids: ['newest', 'oldest', 'price_low_to_high', 'price_high_to_low'],
}

export const Market = () => {
    const { list, loadData, orderBy, setOrderBy, isLoading, refetch, valueSearch, setValueSearch } = useMarket();

    return (

        <Box w="100%">
            <HStack flexWrap="wrap"
            >
                <Box>
                    <Box mb="27px" width="100%">
                        <Button
                            bg="white !important"
                            borderRadius="10px"
                            h="39px"
                            isLoading={isLoading}
                            onClick={refetch}
                        >
                            <Icon as={RefreshIcon} />
                        </Button>
                    </Box>
                </Box>
                <Box w="212px">
                    <Box mb="27px" width="100%">
                        <Select
                            options={ORDERS.ids.map((item) => ({
                                label: ORDERS.data[item],
                                value: item,
                            }))}
                            placeholder="Select Options"
                            onChange={(selected) => setOrderBy(selected.value)}
                            value={{
                                label: ORDERS.data[orderBy as any],
                                value: orderBy,
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    w={{
                        base: '300px',
                        xl: '320px',
                    }}
                >
                    <InputGroup mb="27px">
                        <Input
                            bg="white"
                            placeholder="Search by name or token ID"
                            borderRadius="10px"
                            border="1.5px solid #DFE6F1"
                            outline="none"
                            onChange={(e) => setValueSearch(e?.target?.value)}
                        // onSubmit={() => {
                        //     console.log(`=====SUBMIT=====`);
                        // }}
                        // onKeyPress={(e) => {
                        //     console.log(`-------------------`);
                        //     console.log(e.key);
                        //     console.log(`-------------------`);
                        //     if (e.key === "Enter") {
                        //         loadData();
                        //     }
                        // }}
                        />
                        <InputRightElement
                            pointerEvents="none"
                            children={<Icon as={SearchIcon} color="gray.300" />}
                        />
                    </InputGroup>
                </Box>
            </HStack>
            {isLoading ? <Loading /> : (
                <SimpleGrid
                    columns={{ base: 1, md: 2, xl: 4 }}
                    spacing={4}
                >
                    {list.map((item, idx) => {
                        return (
                            <MarketItem {...item}
                                loadData={loadData}
                            />
                        )
                    })}
                </SimpleGrid>
            )}


            {/* <Stack justify="center" w="100%" marginTop={10}>
                <Box>
                    <PaginationComponent
                        pagesCount={1}
                        currentPage={1}
                        isDisabled={false}
                        onPageChange={() => { }}
                        pages={[1]}
                    />
                </Box>
            </Stack> */}
        </Box >


    )
}