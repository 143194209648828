import ImgShoes24 from "./24.png";
import ImgShoes1 from "./1.png";
import ImgShoes2 from "./2.png";
import ImgShoes3 from "./3.png";
import ImgShoes4 from "./4.png";
import ImgShoes5 from "./5.png";
import ImgShoes6 from "./6.png";
import ImgShoes7 from "./7.png";
import ImgShoes8 from "./8.png";
import ImgShoes9 from "./9.png";
import ImgShoes10 from "./10.png";
import ImgShoes11 from "./11.png";
import ImgShoes12 from "./12.png";
import ImgShoes13 from "./13.png";
import ImgShoes14 from "./14.png";
import ImgShoes15 from "./15.png";
import ImgShoes16 from "./16.png";
import ImgShoes17 from "./17.png";
import ImgShoes18 from "./18.png";
import ImgShoes19 from "./19.png";
import ImgShoes20 from "./20.png";
import ImgShoes21 from "./21.png";
import ImgShoes22 from "./22.png";
import ImgShoes23 from "./23.png";
export const IMG_SHOES = [
    ImgShoes24,
    ImgShoes1,
    ImgShoes2,
    ImgShoes3,
    ImgShoes4,
    ImgShoes5,
    ImgShoes6,
    ImgShoes7,
    ImgShoes8,
    ImgShoes9,
    ImgShoes10,
    ImgShoes11,
    ImgShoes12,
    ImgShoes13,
    ImgShoes14,
    ImgShoes15,
    ImgShoes16,
    ImgShoes17,
    ImgShoes18,
    ImgShoes19,
    ImgShoes20,
    ImgShoes21,
    ImgShoes22,
    ImgShoes23,
]


