import { Contract, BigNumber } from "ethers";
import { useCallback, useEffect, useState } from "react"
import { configEnv } from "~/@config";
import MoverseNft__factory from "~/common/abis/MoverseNft__factory";
import { INft } from "~/dto/moverse-app-api.dto";
import { optionalApiService } from "~/services/@global";
import { useConnectWallet } from "../@global";



const { ADDRESS_CONFIG, CONNECTORS } = configEnv();

export const useNftDetail = ({ tokenId }) => {

    const { library, account } = useConnectWallet();
    const [owner, setOwner] = useState("");

    const [data, setData] = useState<INft>({
        name: "",
        description: "",
        image: "",
        attributes: []
    })

    const loadData = useCallback(async () => {

        try {

            const res = await optionalApiService.get<INft>(`${CONNECTORS.ROOT.baseUrl}/api/public/metadata/${ADDRESS_CONFIG.MOVERSE_NFT}/${tokenId}`);
            setData({
                ...res
            })
        } catch (error) {

        }
    }, [tokenId]);

    const loadOwner = useCallback(async () => {

        try {

            if (!library) {
                throw new Error("library empty");
            }
            const nftCt = new Contract(
                ADDRESS_CONFIG.MOVERSE_NFT,
                MoverseNft__factory.abi,
                library
            )
            const ownerRes = await nftCt.ownerOf(tokenId);

            setOwner(ownerRes);

        } catch (error) {

        }
    }, [library, tokenId]);


    useEffect(() => {
        loadData()
    }, [loadData])

    useEffect(() => {
        loadOwner()
    }, [loadOwner])



    return {
        ...data,
        owner,
        address: ADDRESS_CONFIG.MOVERSE_NFT,
    }
}