import { BigNumber, constants, Contract } from "ethers";
import { useCallback, useEffect, useState } from "react"
import { configEnv } from "~/@config";
import ERC20__factory from "~/common/abis/ERC20__factory";
import MoverseBox__factory from "~/common/abis/MoverseBox__factory";
import PoolAff__factory from "~/common/abis/PoolAff__factory";
import ChainConfig from "~/common/constants/ChainConfig";
import { useAlert, useConnectWallet } from "../@global";

const { MOVERSE_BOX, MOVERSE_NFT, POO_AFF, BUSD } = configEnv().ADDRESS_CONFIG;

export const usePoolAff = ({ nftIdRef }) => {

    const toast = useAlert();
    const { library, account } = useConnectWallet();

    const [isLoading, setLoading] = useState(false);
    const [isApprove, setIsApprove] = useState(false);

    const [qty, setQty] = useState(1);


    const approve = useCallback(async () => {
        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const signer = library.getSigner(account);
            const tokenCt = new Contract(
                BUSD,
                ERC20__factory.abi,
                library
            )
            const { transactionHash } = await (await tokenCt.connect(signer).approve(
                POO_AFF,
                constants.MaxInt256
            )).wait();
            setIsApprove(true);
            toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);
        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
        setLoading(false);
    }, [account, library, toast]);

    const allowance = useCallback(async () => {
        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const tokenCt = new Contract(
                BUSD,
                ERC20__factory.abi,
                library
            )
            const data: BigNumber = await tokenCt.allowance(
                account,
                POO_AFF,
            );
            setIsApprove(data.gt(BigNumber.from(0)));
        } catch (error) {
        }
        setLoading(false);


    }, [account, library]);



    useEffect(() => {
        allowance();
    }, [library, account, allowance]);


    const mint = useCallback(async () => {
        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const sender = library.getSigner(account);
            const poolCt = new Contract(
                POO_AFF,
                PoolAff__factory.abi,
                library
            )
            const { transactionHash } = await (
                await (poolCt.connect(sender).mintByRef(nftIdRef, qty))
            ).wait();


            toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);

        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
        setLoading(false);
    }, [account, library, nftIdRef, qty, toast]);


    return {
        isLoading,
        isApprove,
        approve,
        qty,
        setQty,
        mint
    }
}