import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import sample from 'lodash/sample'
import Metamask from '~/layouts/Svg/Icons/Metamask'
import WalletConnect from '~/layouts/Svg/Icons/WalletConnect'
import RiceConnect from '~/layouts/Svg/Icons/RiceWallet'
import BitKeep from '~/layouts/Svg/Icons/BitKeep'
import TrustWallet from '~/layouts/Svg/Icons/TrustWallet'
import MathWallet from '~/layouts/Svg/Icons/MathWallet'
import TokenPocket from '~/layouts/Svg/Icons/TokenPocket'
import BinanceChain from '~/layouts/Svg/Icons/BinanceChain'
import SafePal from '~/layouts/Svg/Icons/SafePal'
import Coin98 from '~/layouts/Svg/Icons/Coin98';
import { configEnv, IChainConfig, ICodeChainList } from '~/@config';
import { EChainLocalKey, EConnectorId } from '../enums/chain.enum';

const { CHAINS } = configEnv();

const mappingCodeId = () => {
  const mapCodeId = {};
  const mapIdCode = {};
  Object.keys(CHAINS).forEach(chainCode => {
    const { chainId } = CHAINS[chainCode];
    mapCodeId[chainCode] = chainId;
    mapIdCode[chainId] = chainCode;
  })
  return { mapCodeId, mapIdCode }
}

const { mapCodeId, mapIdCode } = mappingCodeId();

const chainIds: number[] = Object.values(CHAINS).map((item) => (item as IChainConfig).chainId);
const chainCodes = Object.keys(CHAINS) as ICodeChainList[];
const listChain = Object.keys(CHAINS).map(chainCode => {
  return {
    ...CHAINS[chainCode],
    chainCode
  }
}) as (IChainConfig & { chainCode: ICodeChainList })[];


const getUrlRpc = () => {
  const currentChainCode = localStorage.getItem(EChainLocalKey.chainCode) || "";
  if (!currentChainCode) {
    return "";
  }
  return sample(CHAINS[currentChainCode].rpcUrls)
}

const getChainId = () => {
  const currentChainCode = localStorage.getItem(EChainLocalKey.chainCode) || "";
  if (!currentChainCode) {
    return 56;
  }
  return CHAINS[currentChainCode].chainId
}

const getUrlScan = () => {
  const currentChainCode = localStorage.getItem(EChainLocalKey.chainCode) || "";
  if (!currentChainCode) {
    return "";
  }
  return sample(CHAINS[currentChainCode].blockExplorerUrls)
}

const rpcByWalletConnect = chainCodes.reduce((result, code) => {
  const { chainId, blockExplorerUrls, rpcUrls } = CHAINS[code];
  const rpcUrl = sample(rpcUrls);
  Object.assign(result, {
    [`${chainId}`]: rpcUrl || ""
  })
  return result;
}, {})


const connectorsByName = {
  [EConnectorId.Injected]: new InjectedConnector({ supportedChainIds: chainIds }),
  [EConnectorId.WalletConnect]: new WalletConnectConnector({
    rpc: rpcByWalletConnect,
    bridge: 'https://pancakeswap.bridge.walletconnect.org/',
    qrcode: true,
    // pollingInterval: POLLING_INTERVAL,
  })
}
const listWallet = [
  {
    title: 'Metamask',
    icon: Metamask,
    connectorId: EConnectorId.Injected,
  },
  {
    title: 'Trust Wallet',
    icon: TrustWallet,
    connectorId: EConnectorId.Injected,
  },
  {
    title: 'WalletConnect',
    icon: WalletConnect,
    connectorId: EConnectorId.WalletConnect,
  }
]



export default {
  chainIds,
  chainCodes,
  mapCodeId,
  mapIdCode,
  getUrlRpc,
  getUrlScan,
  getChainId,
  connectorsByName,
  listWallet,
  listChain,
}
