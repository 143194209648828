
import { Box, Text, HStack, VStack, Button, Image, Icon, Center, Stack } from '@chakra-ui/react'

import { ReactComponent as MoverseTokenIcon } from '~/assets/svgs/icon-token/moverse.svg'
import { useModal } from '~/hooks/@global'
import { SellNftModal } from './SellNftModal'
import { IAsk, INftOfCollection } from '~/dto/market.dto'
import { UpdatePriceNftModal } from './UpdatePriceNftModal'
import { formatMoney } from '~/@helpers/format.helper'
import { CancelListingNftModal } from './CancelListingNftModal'
import { useNavigate } from 'react-router-dom'
import { routers } from '~/common/constants/Router'
import { IMG_SHOES } from '~/assets/shoes'


export interface IMyOrderItemProps extends IAsk {
    loadData: () => Promise<void>;
}

export const MyOrderItem = (props: IMyOrderItemProps) => {
    const { tokenId, image, price, name } = props;
    const navigate = useNavigate();
    const [onPresentUpdatePriceModal] = useModal(<UpdatePriceNftModal {...props} />);
    const [onPresentCancelModal] = useModal(<CancelListingNftModal {...props} />);
    return (
        <Center>
            <Box h="full" w={'full'} bg="white" rounded="10px" overflow={'hidden'}

                onClick={(e) => {

                    navigate(`/${routers.inventory}/${tokenId}`)
                }}
            >
                <Image
                    src={IMG_SHOES[tokenId % IMG_SHOES.length]}
                    objectFit={'cover'}
                    borderRadius="8px"
                />
                <Box mt="4px" mb="12px" py="8px" px="12px">
                    <Stack justifyContent="space-between" h="100%" align={'start'}>
                        <Box>
                            <HStack alignItems="flex-start">
                                <Text
                                    fontSize="12px"
                                    lineHeight="24px"
                                    letterSpacing="-0.5px"
                                    fontWeight="400"
                                    color="#5F6774"
                                >
                                    {"NftFeed NFT"}
                                </Text>
                            </HStack>
                            <Text
                                fontWeight="600"
                                fontSize="15px"
                                lineHeight="18px"
                                color="black.1d"
                                noOfLines={2}
                            >
                                #{tokenId} - {"Sharkie Genesis"}
                            </Text>
                            <HStack>
                                <Text
                                    color="blue.darkest"
                                    fontSize="15px"
                                    lineHeight="20px"
                                    fontWeight="600"
                                >
                                    Price:
                                </Text>
                                <Text
                                    color="primary"
                                    fontSize="15px"
                                    lineHeight="20px"
                                    fontWeight="600"
                                >
                                    {formatMoney(price)}
                                </Text>
                                <Icon as={MoverseTokenIcon} />
                            </HStack>
                        </Box>
                        <Box marginTop="auto" w="100%">
                            <HStack w="100%" mt="14px">
                                <Box flex={1}>
                                    <Button
                                        w="100%"
                                        bg="primary !important"
                                        borderRadius="6px"
                                        fontWeight="600"
                                        fontSize="13px"
                                        lineHeight="20px"
                                        h="auto"
                                        py="5px"
                                        color="black.1d"
                                        border="1.5px solid"
                                        borderColor="yellow.primary"
                                        onClick={onPresentUpdatePriceModal}
                                    >
                                        Update Price
                                    </Button>
                                </Box>
                                <Box flex={1}>
                                    <Button
                                        w="100%"
                                        bg="primary !important"
                                        borderRadius="6px"
                                        fontWeight="600"
                                        fontSize="13px"
                                        lineHeight="20px"
                                        h="auto"
                                        py="5px"
                                        color="black.1d"
                                        border="1.5px solid"
                                        borderColor="yellow.primary"
                                        onClick={onPresentCancelModal}
                                    >
                                        Cancel Listing
                                    </Button>
                                </Box>
                            </HStack>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </Center>
    )
}