import { BigNumber, Contract } from "ethers";
import { useCallback, useEffect, useState } from "react"
import { configEnv } from "~/@config";
import PartnerClaimNft__factory from "~/common/abis/PartnerClaimNft__factory";
import ChainConfig from "~/common/constants/ChainConfig";
import { useAlert, useConnectWallet } from "../@global";



const { PARTNER_CLAIM_NFT } = configEnv().ADDRESS_CONFIG

export const usePartnerClaimNft = () => {
    const toast = useAlert();
    const { library, account } = useConnectWallet();
    const [isLoading, setLoading] = useState(false);


    const [granted, setGranted] = useState(false);

    const [poolInfo, setPoolInfo] = useState({
        totalAllocation: 0,
        totalClaimed: 0
    });

    const loadData = useCallback(async () => {

        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const poolCt = new Contract(
                PARTNER_CLAIM_NFT,
                PartnerClaimNft__factory.abi,
                library
            )

            const [totalAllocation, totalClaimed] = await Promise.all([
                poolCt.totalAllocation(),
                poolCt.totalClaimed()
            ]) as BigNumber[];

            setPoolInfo({
                totalAllocation: totalAllocation.toNumber(),
                totalClaimed: totalClaimed.toNumber()
            })
        } catch (error) {
        }
    }, [account, library]);

    const loadGranted = useCallback(async () => {

        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const poolCt = new Contract(
                PARTNER_CLAIM_NFT,
                PartnerClaimNft__factory.abi,
                library
            )
            const grantedToken = await poolCt.viewGrantedClaim(account);

            setGranted(grantedToken);
        } catch (error) {
        }
    }, [account, library]);

    useEffect(() => {
        loadGranted();
    }, [loadGranted])

    useEffect(() => {
        loadData();
    }, [loadData])

    const claim = useCallback(async () => {
        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const sender = library.getSigner(account);
            const poolCt = new Contract(
                PARTNER_CLAIM_NFT,
                PartnerClaimNft__factory.abi,
                library
            )
            const { transactionHash } = await (
                await (poolCt.connect(sender).claim())
            ).wait();
            loadGranted();
            toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);

        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
        setLoading(false);
    }, [account, library, loadGranted, toast]);


    return {
        isLoading,
        granted,
        ...poolInfo,
        claim
    }
}