import { BigNumberish, utils } from "ethers";

export const compareAddress = (addressA: string, addressB: string) => {
    if (!addressA || !addressB) {
        return false;
    }
    return addressA.toLowerCase().trim() === addressB.toLowerCase().trim();
}

export const delayTime = (ms: number = 3000) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve("Ok")
        }, ms);
    })
}

export const parseAmountToken = (amount: string | number, decimal: number = 18) => {
    return utils.parseUnits(amount.toString(), decimal)
}
export const formatAmountToken = (amount: BigNumberish, decimal: number = 18) => {
    return utils.formatUnits(amount, decimal)
}
