import { Box, Text, Center, SimpleGrid, VStack, Image, HStack } from '@chakra-ui/react'
import { resourceProjects } from '~/data/resources'
import { useWindowSize } from '~/hooks/@global';
import { ItemStaking } from './ItemStaking'
import TextStakingImage from '~/assets/svgs/text-staking.svg'
import TextPackageImage from '~/assets/svgs/text-package.png'
import { FlexibleStaking } from './FlexibleStaking';
import { HistoryView } from '../HistoryView';
import { usePoolFixed } from '~/hooks/pool-fixed';


export const StakingView = () => {
  const { width } = useWindowSize();
  const { allowance, approve, isApprove, isLoading, endTime, startTime } = usePoolFixed();
  return (
    <Box>
      <Box h={"15vh"} />

      <VStack
        bg="transparent"
        w="full"
        mt={{ base: "50px", md: "100px" }}
        mb="90px"
        px={{
          base: "10px",
          md: "0px"
        }}
      >
        <Image src={TextStakingImage} />
      </VStack>

      <Box>

        <Image src={TextPackageImage} />
        <VStack
          style={{
            borderImageSource: "linear-gradient(226.02deg, #E61CFF 0.76%, rgba(245, 28, 250, 0) 30.73%, rgba(255, 28, 247, 0) 60.97%, #FF1CF7 100%)",
            // filter: "blur(2px)"
          }}
          borderWidth="1px"
          borderStyle="solid"
          borderColor="primary"
          borderRadius="2px"
          background="rgba(37, 13, 73, 0.2)"
          boxSizing='border-box'
          pt="40px"
          mt="-20px"
          p={{ base: "10px", md: "40px" }}
        >

          <Text
            color="#fff"
            fontWeight="900"
            fontSize={{ base: "33px", md: "35px" }}
            lineHeight={{ base: "40px", md: "44px" }}
            textTransform="uppercase"
            pt={{ base: "40px", md: "0px" }}
          >
            Locked Staking
          </Text>

          <Text
            color="#fff"
            fontWeight="300"
            fontSize={{ base: "16px", md: "20px" }}
            lineHeight="23px"
            maxW="855px"
            textAlign="center"
            py={{ base: "15px", md: "30px" }}
          >
            Once staked, you will recieve a $SHARKIE immediately at mainnet and won’t be able to withdraw your staked tokens until the staking period ends.
          </Text>
          <HStack
            spacing={4}
          >
            <Text
              color="#fff"
              fontWeight="300"
              fontSize={{ base: "16px", md: "20px" }}
              lineHeight="23px"
              maxW="855px"
              textAlign="center"
              pt={{ base: "40px", md: "0px" }}
            >
              START:
            </Text>
            <Text
              color="primary"
              fontWeight="200"
              fontSize={{ base: "14px", md: "16px" }}
              lineHeight="23px"
              maxW="855px"
              textAlign="center"
              pt={{ base: "40px", md: "0px" }}
            >
              {startTime}
            </Text>
            <Text
              color="#fff"
              fontWeight="300"
              fontSize={{ base: "16px", md: "20px" }}
              lineHeight="23px"
              maxW="855px"
              textAlign="center"
              pt={{ base: "40px", md: "0px" }}
            >
              END:
            </Text>
            <Text
              color="primary"
              fontWeight="300"
              fontSize={{ base: "14px", md: "16px" }}
              lineHeight="23px"
              maxW="855px"
              textAlign="center"
              pt={{ base: "40px", md: "0px" }}
            >
              {endTime}
            </Text>
          </HStack>

          <SimpleGrid
            columns={{
              base: 1,
              md: 2,
              lg: 3
            }}
            spacing={{
              base: 5,
            }}
            w="full"
            mt="-10px"
          >
            {resourceProjects.map((item, index) =>
              <ItemStaking
                {...item}
                approve={approve}
                isApprove={isApprove}
                isLoadingParent={isLoading}
                key={index} />
            )}
          </SimpleGrid>

          {/* <FlexibleStaking /> */}

          <Box pt={{ base: "30px", md: "130px" }} w="full">
            <HistoryView />
          </Box>

        </VStack>

      </Box>

      <Box h={{ base: "10vh", lg: "50vh" }} />

    </Box>

  )
}
