import { Contract, constants, BigNumber } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { configEnv } from "~/@config"
import { formatAmountToken } from "~/@helpers/block-chain.helper";
import { msToUTCString } from "~/@helpers/format.helper";
import ERC20__factory from "~/common/abis/ERC20__factory";
import PoolStakingFixed__factory from "~/common/abis/PoolStakingFixed__factory";
import ChainConfig from "~/common/constants/ChainConfig";
import { resourceProjects } from "~/data/resources";
import { IHistoryItem } from "~/dto/pool-fixed.dto";
import { useAlert, useConnectWallet } from "../@global";

const { POOL_STAKING_FIXED, MOVE } = configEnv().ADDRESS_CONFIG;



export const useOrderStaked = () => {
    const toast = useAlert();
    const { library, account } = useConnectWallet();
    const [isLoading, setLoading] = useState(false);
    const [orders, setOrders] = useState<IHistoryItem[]>([])
    const loadListOrder = useCallback(async () => {
        console.log(`=====loadListOrder=====`);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const poolCt = new Contract(
                POOL_STAKING_FIXED,
                PoolStakingFixed__factory.abi,
                library
            );
            const { data, total } = await poolCt.viewOrdersOfUser(account, 1, 100) as {
                data: {

                    orderId: BigNumber;
                    amount: BigNumber;
                    startTimeLock: BigNumber;
                    endTimeLock: BigNumber;
                    packageIndex: BigNumber;
                    hasWithdraw: boolean;
                }[],
                total: BigNumber
            };

            const mapPackage = resourceProjects.byMap(["packageIndex"]);

            const pipeOrders = data.map(({ orderId, amount,
                startTimeLock,
                endTimeLock,
                hasWithdraw,
                packageIndex }) => ({
                    orderId: orderId.toNumber() || 0,
                    amount: formatAmountToken(amount),
                    startTimeLock: `${msToUTCString(startTimeLock.toNumber() * 1000, "DD/MM/YYYY HH:mm")} UTC`,
                    endTimeLock: `${msToUTCString(endTimeLock.toNumber() * 1000, "DD/MM/YYYY HH:mm")} UTC`,
                    packageTitle: `${mapPackage[packageIndex.toNumber()].month} MONTHS`,
                    endTimeLockBig: endTimeLock,
                    hasWithdraw
                }));

            setOrders(pipeOrders);
        } catch (error) {

        }
    }, [account, library])


    useEffect(() => {
        loadListOrder()
    }, [loadListOrder])


    const withdraw = useCallback(async (orderId: number) => {
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const signer = library.getSigner(account);
            const poolCt = new Contract(
                POOL_STAKING_FIXED,
                PoolStakingFixed__factory.abi,
                library
            )
            const { transactionHash } = await (await poolCt.connect(signer).withdraw(orderId)).wait();

            await loadListOrder();
            toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);

        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
    }, [account, library, loadListOrder, toast]);


    return {
        isLoading,
        orders,
        withdraw
    }

}