import { useCallback } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    HStack,
    Image,
    VStack,
    Box,
    Icon,
    Divider,
} from '@chakra-ui/react'
import { ReactComponent as VerifyIcon } from '~/assets/svgs/components/verify.svg';
import { ReactComponent as MoverseTokenIcon } from '~/assets/svgs/icon-token/moverse.svg'
import { formatMoney } from '~/@helpers/format.helper';
import { useMarketCollection } from '~/hooks/market';
import ConnectWalletButton from '~/layouts/ConnectWalletButton';
import { useConnectWallet } from '~/hooks/@global';
import { IMyOrderItemProps } from './MyOrderItem';
import { BigNumber } from 'ethers';
interface IBuyNftModal extends IMyOrderItemProps {
    isOpen?: boolean;
    onDismiss?: () => any;
}

export const BuyNftModal: React.FC<IBuyNftModal> = (props: IBuyNftModal) => {

    const { onDismiss: onClose = () => {
        return null
    }, isOpen = false, price: inputPrice, exactPrice, loadData, askId } = props;
    const { account } = useConnectWallet();
    const { askSale, isLoading, isApprove, approve } = useMarketCollection();
    const onClickAskSale = useCallback(async () => {
        await askSale(
            askId,
            BigNumber.from(exactPrice),
            async () => {
                loadData();
                onClose();
            }
        )
    }, [askId, askSale, exactPrice, loadData, onClose])

    const renderBtnSell = useCallback(() => {
        if (!account) {
            return <ConnectWalletButton />
        }
        if (!isApprove) {
            return (
                <Button
                    background="linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)"
                    _focus={{
                        backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                    }}
                    _hover={{
                        backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                    }}
                    padding="7.59494px 30.3797px"
                    borderRadius="5px"
                    color="#fff"
                    w="full"
                    isLoading={isLoading}
                    onClick={approve}
                >
                    Aprrove Move
                </Button>
            )
        }

        return (
            <Button
                background="linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)"
                _focus={{
                    backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                }}
                _hover={{
                    backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                }}
                padding="7.59494px 30.3797px"
                borderRadius="5px"
                color="#fff"
                w="full"
                isLoading={isLoading}
                onClick={onClickAskSale}
            >
                Buy Now
            </Button>
        )
    }, [account, approve, isApprove, isLoading, onClickAskSale])

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent maxW="729px" mx="20px">
                    <ModalHeader
                        fontSize="18px"
                        fontWeight="500"
                        letterSpacing="-0.5px"
                        lineHeight="21px"
                        px={{
                            base: '10px',
                            lg: '20px',
                        }}
                        py="12px"
                    >
                        BUY NFT
                    </ModalHeader>
                    <ModalCloseButton />
                    <Divider />
                    <ModalBody
                        px={{
                            base: '20px',
                            lg: '40px',
                        }}
                        py="30px"
                    >

                        <Text
                            fontWeight="400"
                            fontSize="18px"
                            lineHeight="20px"
                            color="grey.66"
                            letterSpacing="-0.5px"
                        >
                            Price
                        </Text>
                        <Text
                            mt="10px"
                            fontSize="18px"
                            fontWeight="600"
                            letterSpacing="-0.5px"
                            lineHeight="20px"
                            color="primary"
                            textTransform="uppercase"
                        >
                            {formatMoney(inputPrice)} MOVE
                        </Text>
                        <Text
                            mt="26px"
                            fontWeight="500"
                            fontSize="16px"
                            lineHeight="24px"
                            color="black.light"
                        >
                            Listing is FREE! When the sale succeeds, the following fees will
                            be charged.
                        </Text>

                        <HStack mt="10px">
                            <Text
                                fontWeight="400"
                                fontSize="15px"
                                lineHeight="18px"
                                color="black.light"
                                w="141px"
                                letterSpacing="-0.5px"
                            >
                                Transaction Fee
                            </Text>
                            <Text
                                fontWeight="400"
                                fontSize="15px"
                                lineHeight="18px"
                                color="black.light"
                            >
                                2%
                            </Text>
                        </HStack>

                        <HStack mt="10px">
                            <Text
                                fontWeight="400"
                                fontSize="15px"
                                lineHeight="18px"
                                color="black.light"
                                w="141px"
                                letterSpacing="-0.5px"
                            >
                                Listing/ Cancel/ Fee
                            </Text>
                            <Text
                                fontWeight="500"
                                fontSize="15px"
                                lineHeight="18px"
                                color="blue.neutral"
                            >
                                FREE
                            </Text>
                        </HStack>

                        <HStack alignItems="flex-start" mt="40px">
                            <Image
                                w="100px"
                                h="100px"
                                src={props?.image}
                                fallbackSrc="/assets/images/empty-item.png"
                                objectFit={'contain'}
                                borderRadius="10px"
                                background="white"
                                filter="drop-shadow(-3px 3px 8px rgba(0, 0, 0, 0.06)) drop-shadow(3px 3px 8px rgba(0, 0, 0, 0.06))"
                            />
                            <VStack alignItems="flex-start">
                                <HStack alignItems="center">
                                    <Text
                                        fontSize="15px"
                                        lineHeight="18px"
                                        letterSpacing="-0.5px"
                                        color="grey.66"
                                    >
                                        {props?.name}
                                    </Text>
                                    <Icon as={VerifyIcon} />
                                </HStack>

                                <Text
                                    mt="12px"
                                    fontSize="18px"
                                    lineHeight="21px"
                                    fontWeight="600"
                                    color="black.1d"
                                    letterSpacing="-0.5px"
                                >
                                    {props?.name} #{props?.tokenId}
                                </Text>
                            </VStack>
                        </HStack>
                    </ModalBody>

                    <ModalFooter w="100%" mt="30px">
                        <HStack flex={1} w="100%">
                            <Box flex={1}>{renderBtnSell()}</Box>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
};

