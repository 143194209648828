import { ChakraProvider, extendTheme, theme } from '@chakra-ui/react'
import { useMemo } from 'react'
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { QueryParamProvider } from 'use-query-params'
import { Web3ReactProvider } from '@web3-react/core'


import { RefreshContextProvider } from './contexts/RefreshContext'
import ModalProvider from './contexts/ModalContext'
import { StakingView } from './views/StakingView'

import { getLibrary } from './@helpers/web3-react.helper'
import { WalletProvider } from './contexts/WalletContext'
import MainLayoutCustom from './layouts/MainLayoutCustom'

import { VestingView } from './views/VestingView'
import { routers } from './common/constants/Router'
import { MintDetailView } from './views/MintDetailView'
import { InventoryView } from './views/InventoryView '
import { SwapView } from './views/SwapView'
import { HomeView } from './views/HomeView'
import { MarketplaceView } from './views/MarketplaceView'
import { LinkAccountView } from './views/LinkAccountView'
import NftDetailView from './views/NftDetailView'


const customTheme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        _focus: { boxShadow: 'none', background: 'transparent' },
        _active: { background: 'transparent', boxShadow: 'none' },
        _hover: { bg: 'transparent' },
      },
    },
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  colors: {
    primary: '#EF5DA8',
    secondary: '#00052E',
  },
  shadows: {
    ...theme.shadows,
    outline: 'none',
  },
})

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const RouteAdapter = ({ children }: { children: any }) => {
  const navigate = useNavigate()
  const location = useLocation()


  const adaptedHistory = useMemo(
    () => ({
      replace(location: any) {
        navigate(location, { replace: true, state: location.state })
      },
      push(location: any) {
        navigate(location, { replace: false, state: location.state })
      },
    }),
    [navigate],
  )
  return children({ history: adaptedHistory, location })
}

function App() {

  return (
    <ChakraProvider theme={customTheme}>
      <QueryParamProvider ReactRouterRoute={RouteAdapter as any}>
        <RefreshContextProvider>
          <WalletProvider>

            <ModalProvider>

              <MainLayoutCustom>
                <Routes>
                  <Route caseSensitive path="/" element={<HomeView />} />
                  <Route path={routers.staking} element={<StakingView />} />
                  <Route path={routers.vesting} element={<VestingView />} />

                  <Route path={routers.affiliate} element={<MintDetailView />} />
                  <Route path={routers.swap} element={<SwapView />} />
                  <Route path={routers.linkAccountApp} element={<LinkAccountView />} />
                  <Route path={routers.affiliate + "/:id"} element={<MintDetailView />} />

                  <Route path={routers.marketplace} element={<MarketplaceView />} />

                  <Route path={routers.inventory} element={<InventoryView />} />

                  <Route path={routers.inventory + "/:tokenId"} element={<NftDetailView />} />


                  <Route path="/*" element={<StakingView />} />
                </Routes>
              </MainLayoutCustom>

            </ModalProvider>
          </WalletProvider>
        </RefreshContextProvider>
      </QueryParamProvider>
    </ChakraProvider>
  )
}

const ContainerApp = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Web3ReactProvider>
  )
}


export default ContainerApp;