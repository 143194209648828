import { Box, Text, Image, HStack, VStack, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Button, } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { IStaking } from '~/dto'
import { useConnectWallet, useWindowSize } from '~/hooks/@global'
import CalendarImage from '~/assets/svgs/calendar.svg'
import { useCallback } from 'react'
import ConnectWalletButton from '~/layouts/ConnectWalletButton'
import { usePoolFixedItem } from '~/hooks/pool-fixed'

const ItemRowInfo = (props: { title: string, value: string | number }) => {
    const { title, value } = props;
    return (
        <HStack
            justifyContent="space-between"
            w="full"
            pb="8px"
        >
            <Text
                fontWeight="400"
                fontSize="15px"
                lineHeight="19px"
                color="#fff"
                textAlign="left"
            >
                {title}
            </Text>
            <Text
                fontWeight="400"
                fontSize="15px"
                lineHeight="19px"
                color="#fff"
                textAlign="right"
            >
                {value}
            </Text>
        </HStack>
    )
}


interface IProps extends IStaking {
    isApprove: boolean;
    isLoadingParent: boolean;
    approve: () => Promise<void>;
}

export const ItemStaking = (props: IProps) => {

    const { amountTokenStake, apr, month, totalLockValue, packageIndex, approve, isApprove, isLoadingParent } = props;
    const { account } = useConnectWallet();

    const { isLoading, stake, price, timeLock, totalAllocation, totalBought, totalValueLocked, percentStaked, myStaked } = usePoolFixedItem({ packageIndex });
    const { width } = useWindowSize();
    const navigate = useNavigate();


    const renderButton = useCallback(() => {
        if (!account) {
            return <ConnectWalletButton />
        }

        if (!isApprove) {
            return (
                <Button
                    w="full"
                    background="linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)"
                    _focus={{
                        backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                    }}
                    _hover={{
                        backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                    }}
                    padding="7.59494px 30.3797px"
                    borderRadius="5px"
                    color="#fff"
                    isLoading={isLoadingParent}
                    onClick={approve}
                >
                    Approve $SHARKIE
                </Button>
            )
        }
        return (
            <Button
                w="full"
                background="linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)"
                _focus={{
                    backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                }}
                _hover={{
                    backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                }}
                padding="7.59494px 30.3797px"
                borderRadius="5px"
                color="#fff"
                isLoading={isLoading}
                onClick={stake}
            >
                Stake
            </Button>
        )
    }, [account, approve, isApprove, isLoading, isLoadingParent, stake])

    return (
        <Box
            cursor="pointer"
            borderColor="#6B6785"
            borderWidth="0.949367px"
            borderStyle="solid"
            background="rgba(26, 0, 60, 0.3)"
            _hover={{
                borderColor: "primary",
                background: "linear-gradient(126.12deg, rgba(255, 0, 255, 0.2) -1.57%, rgba(255, 28, 247, 0) 109.63%)",
                backdropFilter: "blur(5.6962px)"
            }}
        >
            <Box
                borderRadius="12px"
                px="23px"
                py="20px"
            >

                <HStack
                    bg="primary"
                    boxShadow="0px 0px 16px rgba(255, 0, 255, 0.69)"
                    padding="2px 8px"
                    w="fit-content"
                >
                    <Image src={CalendarImage} h="20px" w="24px" />
                    <Text
                        fontWeight="500"
                        fontSize="18px"
                        lineHeight="160%"
                        color="#fff"
                        textTransform="uppercase"
                    >
                        {month} months
                    </Text>
                </HStack>

                <Text
                    color="primary"
                    fontWeight="400"
                    fontSize="25px"
                    lineHeight="120%"
                    textAlign="center"
                    py="20px"
                >
                    {amountTokenStake} $SHARKIE
                </Text>

                <HStack w="full" justifyContent="space-between">
                    <Text
                        fontWeight="400"
                        fontSize="15px"
                        lineHeight="19px"
                        color="#fff"
                    >
                        {totalBought} Staked
                    </Text>
                    <Text
                        fontWeight="400"
                        fontSize="15px"
                        lineHeight="19px"
                        color="#fff"
                    >
                        {totalAllocation}
                    </Text>
                </HStack>

                <Slider aria-label="slider-ex-4" value={percentStaked} >
                    <SliderTrack
                        bg='transparent'
                        h="13px"
                        borderRadius="38px"
                        border="0.5px solid rgba(128, 19, 162, 0.2)"
                    >
                        <SliderFilledTrack
                            background="linear-gradient(270deg, rgba(255, 28, 247, 0.6) 6.34%, rgba(255, 28, 247, 0.08) 66.56%)"
                            boxShadow="0px 0px 6px rgba(255, 28, 247, 0.41)"
                            borderRadius="38px"
                        />
                        <SliderThumb boxSize={6} style={{ background: 'transparent' }}>
                            <Image src={'/svgs/circle-icon.svg'} />
                        </SliderThumb>
                    </SliderTrack>
                </Slider>

                <VStack mt="20px">
                    <ItemRowInfo title='Total locked value' value={`${totalValueLocked} $SHARKIE`} />
                    <ItemRowInfo title='APR%' value={`${apr}`} />
                    <ItemRowInfo title='My staked' value={`${myStaked} $SHARKIE`} />
                    <ItemRowInfo title='My rewards' value={'$SHARKIE'} />
                </VStack>

                <VStack mt="20px">

                    <HStack w="full">
                        {renderButton()}

                    </HStack>

                </VStack>

            </Box>
        </Box >
    )
}
