
import { SimpleGrid } from '@chakra-ui/react'
import Loading from '~/components/Loading';
import { useMyNft } from '~/hooks/market'
import { MyNftItem } from './MyNftItem'


export const MyNft = () => {
    const { list, loadData, isLoading } = useMyNft();
    return (
        <>
            {isLoading ? <Loading /> : (
                <SimpleGrid
                    columns={{ base: 1, md: 2, xl: 4 }}
                    spacing={4}
                >
                    {list.map((item, idx) => {
                        return (
                            <MyNftItem {...item}
                                loadData={loadData}
                            />
                        )
                    })}
                </SimpleGrid>

            )
            }
        </>
    )
}