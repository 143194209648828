
import { useCallback, useEffect, useState } from "react"
import { configEnv } from "~/@config";
import { INftOfCollection } from "~/dto/market.dto";
import nftMarketplaceServices from "~/services/nft-marketplace.services";
import { useConnectWallet } from "../@global";


const { ADDRESS_CONFIG } = configEnv();

const { MOVERSE_NFT } = ADDRESS_CONFIG;

export const useMyNft = () => {

    const { account } = useConnectWallet();

    const [isLoading, setLoading] = useState(false);

    const [list, setList] = useState<INftOfCollection[]>([]);


    const loadData = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await nftMarketplaceServices.myItemsOfCollection({
                account,
                pageIndex: 1,
                pageSize: 100,
                collection: MOVERSE_NFT
            })
            setList(data);
        } catch (error) {

        }
        setLoading(false);
    }, [account])


    useEffect(() => { loadData() }, [loadData]);

    return {
        isLoading,
        loadData,
        list
    }
}