import { Box, HStack, Image, Tooltip, } from '@chakra-ui/react'
import { ISocial } from '~/dto'

export const SocialsLayout = (props: {
    socialLinks: ISocial[]
}) => {
    const { socialLinks } = props;
    return (
        <HStack spacing={2}>
            {socialLinks.length > 0 &&
                socialLinks.map((item, idx) => {
                    return (
                        <Tooltip
                            label={item.name}
                            placement='top-start'
                            borderRadius="8px"
                            key={idx}
                            fontFamily={"Play"}
                        >
                            <Box
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    window.open(item.href, '_blank')
                                }}
                            >
                                <Image
                                    src={item.icon}
                                    h="28px"
                                />
                            </Box>
                        </Tooltip>
                    )
                })
            }
        </HStack>
    )
}
