import { useCallback, FC } from 'react'
import {
  Box,
  Flex,
  HStack,
  useDisclosure,
  Text,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Accordion,
  Image,
  Button,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from '@chakra-ui/react'

import ConnectWalletButton from './ConnectWalletButton'
import LogoWeb from '~/assets/svgs/header/logo.png'
import NavLinkItem from './NavLinkItem'
import HamburgerIcon from '~/assets/svgs/hamburger-menu.svg'
import MenuOpenIcon from '~/assets/svgs/menu_open.svg'
import { MainLinks } from '~/common/constants/HeaderRouter'
import { useNavigate } from 'react-router-dom'
import { useConnectWallet, useWalletModal } from '~/hooks/@global'
import { routers } from '~/common/constants/Router'

const MobileDrawer = ({ onClose, isOpen }) => {
  return (
    <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
      <DrawerContent mt="65px">
        <DrawerHeader />
        <DrawerContent>
          <DrawerBody px="16px">
            <Accordion allowMultiple>
              {MainLinks.map((link, indexLink) => {
                return (
                  <Box key={link.key} mt="33px">
                    <NavLinkItem {...link} onClose={onClose} />
                  </Box>
                )
              })}
            </Accordion>
          </DrawerBody>
        </DrawerContent>
      </DrawerContent>
    </Drawer >
  )
}

const HeaderMobile = () => {
  const history = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { login, logout, account } = useConnectWallet()
  const { onPresentAccountModal } = useWalletModal(login, logout, account)

  const renderWallet = useCallback(() => {
    if (account) {
      return (
        <Box>
          <Popover trigger={'click'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Button
                p="0px"
                m="0"
                color="#000"
                bg="transparent"
                _hover={{
                  bg: "transparent"
                }}
                _focus={{
                  bg: "transparent"
                }}
              >
                <Text fontSize="16px" lineHeight="16px" color="#fff">
                  {account?.substring(0, 4) ?? ''}...
                  {account?.substring(account.length - 4) ?? ''}
                </Text>
              </Button>
            </PopoverTrigger>

            <PopoverContent
              border={0}
              boxShadow="0px 4px 6px rgba(222, 222, 222, 0.25) !important"
              background="linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)"
              p="20px"
              rounded="0px 0px 8px 8px"
              w="auto"
            >
              <VStack alignItems="start" spacing={4}>

                <Text
                  onClick={() => {
                    history(routers.inventory)
                  }}
                  fontSize="16px"
                  lineHeight="16px"
                  color="#fff"
                >
                  Inventory
                </Text>

                <Text
                  onClick={() => logout()}
                  fontSize="16px"
                  lineHeight="16px"
                  color="#fff"
                >
                  Logout
                </Text>
              </VStack>

            </PopoverContent>

          </Popover>

        </Box>
      )
    }
    return <ConnectWalletButton />
  }, [account, history, logout])

  const renderIcon = useCallback(() => {
    return (
      <Image
        size="sm"
        src={
          isOpen ? MenuOpenIcon : HamburgerIcon
        }
        fontSize="22px"
        onClick={isOpen ? onClose : onOpen}
      />
    )
  }, [isOpen, onClose, onOpen])

  return (
    <Box
      pt="10px"
      px={{
        base: '15px',
      }}
      h={{
        base: '65px',
      }}
      bg="rgba(0, 0, 0, 0.1)"
      position="fixed"
      zIndex={99}
      w="100%"
    >
      <Flex h="100%" alignItems="center" justifyContent="space-between">
        {renderIcon()}

        <Box display={{ base: 'flex' }}>
          <Image
            src={LogoWeb}
            w="220px"
            h="50px"
            objectFit="cover"
            onClick={() => {
              history('/')
            }}
          />
        </Box>

        {account ? (
          <HStack
            background="linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)"
            color="#fff"
            borderRadius="10px"
            // onClick={onPresentAccountModal}
            px="5px"
          >
            {/* <Text fontSize="16px" lineHeight="13px" color="#fff">
              0.00
            </Text>
            <Icon as={MoverseTokenIcon} h="24px" w="24px" /> */}
            {renderWallet()}
          </HStack>
        ) : (
          <Box>
            <ConnectWalletButton />
          </Box>
        )}

      </Flex>

      <MobileDrawer onClose={onClose} isOpen={isOpen} />
    </Box>
  )
}

export default HeaderMobile
