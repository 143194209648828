import { Contract, constants, BigNumber } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { configEnv } from "~/@config"
import { formatAmountToken, parseAmountToken } from "~/@helpers/block-chain.helper";
import { formatMoney, msToUTCString } from "~/@helpers/format.helper";
import ERC20__factory from "~/common/abis/ERC20__factory";
import PoolStakingAutoAprV1__factory from "~/common/abis/PoolStakingAutoAprV1__factory";
import ChainConfig from "~/common/constants/ChainConfig";
import { useAlert, useConnectWallet } from "../@global";




const { POOL_STAKING_AUTO_APR, MOVE } = configEnv().ADDRESS_CONFIG;



export const usePoolAuto = () => {
    const toast = useAlert();
    const { library, account } = useConnectWallet();

    const [isLoading, setLoading] = useState(false);
    const [isLoadingWithdraw, setLoadingWithdraw] = useState(false);
    const [isLoadingRedeem, setLoadingRedeem] = useState(false);


    const [isApprove, setIsApprove] = useState(false);
    const [amount, setAmount] = useState('');

    const [apr, setApr] = useState(0);

    const [userInfo, setUserInfo] = useState({
        qtyRedeem: 0,
        qtyRedeemable: 0,
        amountStaked: '0',
        pendingReward: '0',
    })

    const [poolInfo, setPoolInfo] = useState({
        totalRedeem: 0,
        totalUserStaked: 0,
        totalValueLocked: '0',
        totalReward: '0',
        totalPointRewarded: '0',
        startTime: '',
        endTime: '',
    })

    const loadUserInfo = useCallback(async () => {
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const poolCt = new Contract(
                POOL_STAKING_AUTO_APR,
                PoolStakingAutoAprV1__factory.abi,
                library
            )

            const { amountStaked,
                qtyRedeem,
                qtyRedeemable,
                pendingReward } = await poolCt.viewUserInfo(account) as {
                    amountStaked: BigNumber;
                    qtyRedeem: BigNumber;
                    pendingReward: BigNumber;
                    qtyRedeemable: BigNumber;
                }
            setUserInfo({
                qtyRedeem: qtyRedeem.toNumber(),
                qtyRedeemable: qtyRedeemable.toNumber(),
                amountStaked: formatMoney(Number(formatAmountToken(amountStaked)), 2),
                pendingReward: formatMoney(Number(formatAmountToken(pendingReward)), 2),
            })

        } catch (error) {
        }
    }, [account, library]);

    const loadPoolInfo = useCallback(async () => {
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const poolCt = new Contract(
                POOL_STAKING_AUTO_APR,
                PoolStakingAutoAprV1__factory.abi,
                library
            )
            const {
                totalValueLocked,
                totalRedeem,
                totalUserStaked,
                totalReward,
                totalPointRewarded,
                startTime,
                endTime
            } = await poolCt.viewPoolInfo() as {
                totalValueLocked: BigNumber;
                totalRedeem: BigNumber;
                totalUserStaked: BigNumber;
                totalReward: BigNumber;
                totalPointRewarded: BigNumber;
                startTime: BigNumber;
                endTime: BigNumber;
            }
            setPoolInfo({
                totalRedeem: totalRedeem.toNumber(),
                totalUserStaked: totalUserStaked.toNumber(),
                totalValueLocked: formatMoney(Number(formatAmountToken(totalValueLocked)), 2),
                totalReward: formatMoney(Number(formatAmountToken(totalReward)), 2),
                totalPointRewarded: formatMoney(Number(formatAmountToken(totalPointRewarded)), 2),
                startTime: msToUTCString(startTime.toNumber() * 1000),
                endTime: msToUTCString(endTime.toNumber() * 1000),

            })
        } catch (error) {
            console.log(`=====PoolINFO=====`, error);
        }
    }, [account, library]);

    const calculateApr = useCallback(async () => {
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const poolCt = new Contract(
                POOL_STAKING_AUTO_APR,
                PoolStakingAutoAprV1__factory.abi,
                library
            )
            const amount = parseAmountToken(10000000);
            const initValueStake = parseAmountToken(10000)
            const multilayer = 100;
            const resApr: BigNumber = await poolCt.calculateApr(
                amount,
                initValueStake,
                multilayer
            );

            setApr(resApr.toNumber() / multilayer)

        } catch (error) {

        }
    }, [account, library])


    useEffect(() => {
        loadPoolInfo();
    }, [loadPoolInfo])

    useEffect(() => {
        loadUserInfo();
    }, [loadUserInfo]);

    useEffect(() => {
        calculateApr();
    }, [calculateApr])

    const approve = useCallback(async () => {
        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const signer = library.getSigner(account);
            const tokenCt = new Contract(
                MOVE,
                ERC20__factory.abi,
                library
            )
            const { transactionHash } = await (await tokenCt.connect(signer).approve(
                POOL_STAKING_AUTO_APR,
                constants.MaxInt256
            )).wait();
            setIsApprove(true);
            toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);
        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
        setLoading(false);
    }, [account, library, toast]);

    const allowance = useCallback(async () => {
        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const tokenCt = new Contract(
                MOVE,
                ERC20__factory.abi,
                library
            )
            const data: BigNumber = await tokenCt.allowance(
                account,
                POOL_STAKING_AUTO_APR,
            );
            setIsApprove(data.gt(BigNumber.from(0)));
        } catch (error) {
        }
        setLoading(false);


    }, [account, library]);



    useEffect(() => {
        allowance();
    }, [library, account, allowance]);

    const stake = useCallback(async () => {
        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const signer = library.getSigner(account);
            const poolCt = new Contract(
                POOL_STAKING_AUTO_APR,
                PoolStakingAutoAprV1__factory.abi,
                library
            )
            const { transactionHash } = await (await poolCt.connect(signer).stake(
                parseAmountToken(amount)
            )).wait();

            await Promise.all([
                loadPoolInfo(),
                loadUserInfo(),
                calculateApr()
            ])
            toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);

        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
        setLoading(false);
    }, [account, amount, calculateApr, library, loadPoolInfo, loadUserInfo, toast]);

    const withdraw = useCallback(async () => {
        setLoadingWithdraw(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const signer = library.getSigner(account);
            const poolCt = new Contract(
                POOL_STAKING_AUTO_APR,
                PoolStakingAutoAprV1__factory.abi,
                library
            )
            const { transactionHash } = await (await poolCt.connect(signer).withdraw(
                parseAmountToken(amount)
            )).wait();

            await Promise.all([
                loadPoolInfo(),
                loadUserInfo(),
                calculateApr()
            ])
            toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);

        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
        setLoadingWithdraw(false);
    }, [account, amount, calculateApr, library, loadPoolInfo, loadUserInfo, toast]);

    const redeem = useCallback(async () => {
        setLoadingRedeem(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const signer = library.getSigner(account);
            const poolCt = new Contract(
                POOL_STAKING_AUTO_APR,
                PoolStakingAutoAprV1__factory.abi,
                library
            )
            const { transactionHash } = await (await poolCt.connect(signer).redeem(1)).wait();

            await Promise.all([
                loadPoolInfo(),
                loadUserInfo(),
                calculateApr()
            ])
            toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);

        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
        setLoadingRedeem(false);
    }, [account, calculateApr, library, loadPoolInfo, loadUserInfo, toast]);

    return {
        isLoading,
        isLoadingWithdraw,
        isLoadingRedeem,
        isApprove,
        approve,
        allowance,
        stake,
        withdraw,
        redeem,
        amount,
        setAmount,
        userInfo,
        poolInfo,
        apr: `${formatMoney(apr, 2)} %`
    }

}