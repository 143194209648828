import { Box, VStack, Tab, TabList, TabPanel, TabPanels, Tabs, SimpleGrid } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useWindowSize } from '~/hooks/@global'
import useCopyToClipboard from '~/hooks/@common/useCopyToClipboard'
import { configEnv } from '~/@config'
import { AskItem } from './AskItem'
import { MyNft } from './MyNft'
import { MyOrder } from './MyOrder'
import { Market } from './Market'


const { DOMAIN_URL } = configEnv();



export const MarketplaceView = () => {
    const { width } = useWindowSize();
    const navigate = useNavigate();

    const [value, copy] = useCopyToClipboard();

    return (
        <Box>
            <Box h={"15vh"} />
            <Tabs variant='unstyled' pt={{ base: "0px", md: "10px" }} isFitted isLazy>
                <VStack>
                    <TabList w={"80%"}>

                        <Tab
                            color="#fff"
                            _selected={{ bg: "primary" }}
                            fontWeight={500}
                            fontSize={{ base: "14px", md: "20px" }}
                            lineHeight='15px'
                            border="1px solid #FF00FF"
                            p={{ base: "5px", lg: "11px 51px" }}
                        >
                            Market
                        </Tab>
                        <Tab
                            color="#fff"
                            _selected={{ bg: "primary" }}
                            fontWeight={500}
                            fontSize={{ base: "14px", md: "20px" }}
                            lineHeight='15px'
                            border="1px solid #FF00FF"
                            p={{ base: "5px", lg: "11px 51px" }}
                        >
                            My Order
                        </Tab>
                        <Tab
                            color="#fff"
                            _selected={{ bg: "primary" }}
                            fontWeight={500}
                            fontSize={{ base: "14px", md: "20px" }}
                            lineHeight='15px'
                            border="1px solid #FF00FF"
                            p={{ base: "5px", lg: "11px 51px" }}
                        >
                            My Nft
                        </Tab>

                    </TabList>
                </VStack>

                <TabPanels pt={{ base: "15px", md: "45px" }}>

                    <TabPanel>
                        <Market />
                    </TabPanel>
                    <TabPanel>
                        <MyOrder />
                    </TabPanel>
                    <TabPanel>
                        <MyNft />
                    </TabPanel>
                </TabPanels>
            </Tabs>


            <Box h={{ base: "10vh", lg: "50vh" }} />
        </Box>
    )
}
