import {
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import { ReactComponent as NoteIcon } from '~/assets/svgs/note-icon.svg'
import { ReactComponent as MoverseTokenIcon } from '~/assets/svgs/icon-token/moverse.svg'

const PriceInput = ({ onChange, value }) => {
  return (
    <>
      <InputGroup>
        <Input
          value={value}
          onChange={onChange}
          borderColor="#448AFF !important"
          borderWidth="1px"
          borderStyle="solid"
          placeholder="0.000"
          borderRadius="10px"
          textAlign="right"
          fontWeight="500"
          mr="106px"
          fontSize="32px"
          lineHeight="20px"
          h="56px"
          bg="#F7F9FA"
        />
        <InputRightElement
          bg="#EFF3F9"
          borderTopColor="#448AFF !important"
          borderRightColor="#448AFF !important"
          borderBottomColor="#448AFF !important"
          borderWidth="1px"
          borderStyle="solid"
          borderTopRightRadius="10px"
          borderBottomRightRadius="10px"
          w="136px"
          children={
            <HStack>
              <Icon as={MoverseTokenIcon} w="32px" h="32px" />
              <Text
                fontSize="24px"
                lineHeight="20px"
                color="primary"
                letterSpacing="-0.5px"
              >
                MOVE
              </Text>
            </HStack>
          }
          h="56px"
        />
      </InputGroup>
      <HStack align="center" mt="10px">
        <Icon as={NoteIcon} w="29px" h="28px" />
        <Text
          fontSize="13px"
          color="grey.97"
          lineHeight="15px"
          letterSpacing="-0.5px"
        >
          The comma (,) is to separate groups of thousands, the dot (.) is to
          separate decimals
        </Text>
      </HStack>
    </>
  )
}

export default PriceInput
