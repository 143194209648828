import { IAsk, INftOfCollection, IOrderBy } from '~/dto/market.dto'
import { rootApiService } from './@global'

const Endpoint = {
  myItemsOfCollection: 'api/public/market-user/my-items-of-collection',
  listAskByUser: "api/public/market-user/on-sales",
  listAskByCollection: "api/public/nft-collection/list-ask-by-collection"
}



export class NFTMarketPlaceServices {

  async myItemsOfCollection(variables: {
    account: string
    pageSize: number
    pageIndex: number
    collection: string
  }): Promise<{
    data: INftOfCollection[],
    total: number
  }> {
    return rootApiService.post(
      Endpoint.myItemsOfCollection,
      variables,
    )
  }

  async listAskByUser(variables: {
    account: string
    pageSize: number
    pageIndex: number
    valueSearch?: string
  }): Promise<{
    data: IAsk[],
    total: number
  }> {
    return rootApiService.post(
      Endpoint.listAskByUser,
      variables,
    )
  };


  async listAskByCollection(variables: {
    address: string
    pageSize: number
    pageIndex: number
    valueSearch?: string
    order?: IOrderBy
  }): Promise<{
    data: IAsk[],
    total: number
  }> {

    return rootApiService.post(
      Endpoint.listAskByCollection,
      {
        ...variables,
        order: variables.order ? variables.order : "newest"
      },
    )
  }
}

export default new NFTMarketPlaceServices()
