export const routers = {
  tokenomics: 'tokenomics',
  staking: 'staking',
  vesting: 'vesting',
  affiliate: 'affiliate',
  inventory: 'inventory',
  swap: 'swap',
  marketplace: 'marketplace',
  linkAccountApp: "linkAccountApp",
}
