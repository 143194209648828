
import { useCallback, useEffect, useState } from "react"
import { configEnv } from "~/@config";
import { IAsk, IOrderBy } from "~/dto/market.dto";
import nftMarketplaceServices from "~/services/nft-marketplace.services";

const { ADDRESS_CONFIG } = configEnv();
const { MOVERSE_NFT } = ADDRESS_CONFIG;


let cacheOrderBy: IOrderBy = "newest";

export const useMarket = () => {
    const [isLoading, setLoading] = useState(false);

    const [orderBy, setOrderBy] = useState<IOrderBy>(cacheOrderBy);
    const [valueSearch, setValueSearch] = useState("");
    const [list, setList] = useState<IAsk[]>([]);
    const refetch = useCallback(async () => {
        setOrderBy("newest");
        setValueSearch("");
    }, [])

    const loadData = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await nftMarketplaceServices.listAskByCollection({
                address: MOVERSE_NFT,
                pageIndex: 1,
                pageSize: 100,
                order: orderBy,
                valueSearch: valueSearch
            })
            setList(data);
        } catch (error) {

        }
        setLoading(false);
    }, [orderBy, valueSearch])



    useEffect(() => { loadData() }, [loadData]);
    useEffect(() => { cacheOrderBy = orderBy }, [orderBy]);
    return {
        isLoading,
        orderBy,
        setOrderBy,
        valueSearch,
        setValueSearch,
        loadData,
        refetch,
        list
    }
}