import { HStack, Center, Box, VStack, Button, Text, Image, Checkbox } from "@chakra-ui/react";
import { ChangeEvent, useCallback } from "react";
import { IMG_SHOES } from "~/assets/shoes";


export interface IBuyNftItemProps {
    tokenId: number;
    checkedNft: Set<number>;
    onChangeCheckBox: (e: ChangeEvent<HTMLInputElement>, tokenId: number) => void;
}

export const BuyNftItem = (props: IBuyNftItemProps) => {


    const { tokenId, checkedNft, onChangeCheckBox } = props;

    const renderCheckBox = useCallback(() => {
        return (
            <Checkbox
                isChecked={checkedNft.has(tokenId)}
                onChange={(e) => onChangeCheckBox(e, tokenId)}
                size="md"
                position="absolute"
                top="10px"
                right="10px"
                borderColor="#fff"
                colorScheme='white'
                _checked={{
                    background: "primary"
                }}
            />
        )
    }, [checkedNft, onChangeCheckBox, tokenId])

    return (
        <HStack
            borderColor="#6B6785"
            borderWidth="0.949367px"
            borderStyle="solid"
            background="rgba(26, 0, 60, 0.3)"
            borderRadius="12px"
            p="15px"
            w="full"
            justifyContent="space-between"
            direction={{
                base: 'column',
            }}
            flexWrap={{
                base: 'wrap',
            }}
            position="relative"
        >
            <Center
                w={"full"}
                mb={{
                    base: "15px",
                }}
            >
                <Box bg="#125739" borderRadius="8px">
                    <Image
                        src={IMG_SHOES[tokenId % IMG_SHOES.length]}
                        height="90%"
                    />
                </Box>
                {renderCheckBox()}
            </Center>

            <VStack
                justifyContent="space-between"
                spacing={6}
                pr={"5%"}
                w="full"
                mb={{
                    base: "15px",
                }}
            >

                <HStack justifyContent="space-between" spacing={6} w="full">
                    <Text
                        color="#fff"
                        fontWeight="bold"
                        fontSize={{
                            base: "13px",
                        }}
                        lineHeight={{
                            base: "19px",
                        }}
                        letterSpacing="0.03em"
                    >
                        TokenId
                    </Text>
                    <Text
                        color="#fff"
                        fontWeight="bold"
                        fontSize={{
                            base: "12p13pxx",
                        }}
                        lineHeight={{
                            base: "19px",
                        }}
                        letterSpacing="0.03em"
                    >
                        #{tokenId}
                    </Text>
                </HStack>
            </VStack>
        </HStack>
    )
}