import { Box, Text, VStack, Button, Center, HStack } from '@chakra-ui/react'
import { useCallback } from 'react'
import { pipeAddressUi } from '~/@helpers/format.helper'
import LoginModal from '~/container/LoginModal'
import { useConnectWallet, useModal } from '~/hooks/@global'
import { useLinkAccount } from '~/hooks/link-account'
import ConnectWalletButton from '~/layouts/ConnectWalletButton'


const ItemRowInfo = (props: { title: string, value: string | number }) => {
    const { title, value } = props;
    return (
        <HStack

            marginTop={"10"}
            justifyContent="space-between"
            w="full"
            pb="8px"
        >
            <Text
                fontWeight="400"
                fontSize="15px"
                lineHeight="19px"
                color="#fff"
                textAlign="left"
            >
                {title}
            </Text>
            <Text
                fontWeight="400"
                fontSize="15px"
                lineHeight="19px"
                color="#fff"
                textAlign="left"
            >
                {value}
            </Text>
        </HStack>
    )
}

export const LinkAccountView = () => {


    const { account } = useConnectWallet();
    const { login, isLoading, name, id, linkAccount, isLinked } = useLinkAccount();
    const [onPresentModal] = useModal(<LoginModal isLoading={isLoading} login={login} />)

    const renderBtn = useCallback(() => {
        if (!account) {
            return <ConnectWalletButton />
        }
        if (!id) {
            return (
                <Button
                    background="linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)"
                    _focus={{
                        backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                    }}
                    _hover={{
                        backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                    }}
                    padding="7.59494px 30.3797px"
                    borderRadius="5px"
                    color="#fff"
                    w="244px"
                    onClick={onPresentModal}
                >
                    Login app account
                </Button>
            )
        }
        if (isLinked) {
            return (
                <Button
                    background="linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)"
                    _focus={{
                        backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                    }}
                    _hover={{
                        backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                    }}
                    padding="7.59494px 30.3797px"
                    borderRadius="5px"
                    color="#fff"
                    w="244px"
                    disabled={true}
                >
                    Account linked wallet
                </Button>
            )
        }
        return (
            <Button
                background="linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)"
                _focus={{
                    backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                }}
                _hover={{
                    backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                }}
                padding="7.59494px 30.3797px"
                borderRadius="5px"
                color="#fff"
                w="244px"
                onClick={() => linkAccount()}
                isLoading={isLoading}
            >
                Link account
            </Button>
        )
    }, [account, id, isLinked, isLoading, linkAccount, onPresentModal])

    return (
        <Box>
            <Box h={"20vh"} />
            <Center>
                <Box
                    cursor="pointer"
                    borderColor="#6B6785"
                    borderWidth="0.949367px"
                    borderStyle="solid"
                    background="rgba(26, 0, 60, 0.3)"
                    borderRadius="12px"
                    p={{ base: "15px", md: "45px" }}
                >
                    <Text
                        fontWeight="400"
                        fontSize="30px"
                        lineHeight="19px"
                        color="primary"
                        textAlign="left"
                    >
                        {" Link account app and wallet"}
                    </Text>
                    <ItemRowInfo title='Name' value={name} />
                    <VStack mt="20px">
                        {renderBtn()}
                    </VStack>
                </Box>
            </Center>

            <Box h={{ base: "10vh", lg: "50vh" }} />

        </Box>
    )
}
