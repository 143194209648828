import { Contract, constants, BigNumber } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { configEnv } from "~/@config"
import { msToUTCString } from "~/@helpers/format.helper";
import ERC20__factory from "~/common/abis/ERC20__factory";
import PoolStakingFixed__factory from "~/common/abis/PoolStakingFixed__factory";
import ChainConfig from "~/common/constants/ChainConfig";
import { useAlert, useConnectWallet } from "../@global";




const { POOL_STAKING_FIXED, MOVE } = configEnv().ADDRESS_CONFIG;



export const usePoolFixed = () => {
    const toast = useAlert();
    const { library, account } = useConnectWallet();
    const [isLoading, setLoading] = useState(false);
    const [isApprove, setIsApprove] = useState(false);
    const [timeActivePool, setTimeActivePool] = useState({
        startTime: "",
        endTime: ""
    })

    const approve = useCallback(async () => {
        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const signer = library.getSigner(account);
            const tokenCt = new Contract(
                MOVE,
                ERC20__factory.abi,
                library
            )
            const { transactionHash } = await (await tokenCt.connect(signer).approve(
                POOL_STAKING_FIXED,
                constants.MaxInt256
            )).wait();
            setIsApprove(true);
            toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);
        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
        setLoading(false);
    }, [account, library, toast]);

    const allowance = useCallback(async () => {

        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }

            const tokenCt = new Contract(
                MOVE,
                ERC20__factory.abi,
                library
            )
            const data: BigNumber = await tokenCt.allowance(
                account,
                POOL_STAKING_FIXED,
            );
            setIsApprove(data.gt(BigNumber.from(0)));
        } catch (error) {
        }
        setLoading(false);


    }, [account, library]);

    const loadTimeActivePool = useCallback(async () => {
        setLoading(true);
        try {
            if (!library) {
                throw new Error("Library empty");
            }
            const poolCt = new Contract(
                POOL_STAKING_FIXED,
                PoolStakingFixed__factory.abi,
                library
            )
            const [startTime, endTime] = await Promise.all([
                poolCt.startTime(),
                poolCt.endTime()
            ]) as BigNumber[];
            setTimeActivePool({
                startTime: `${msToUTCString(startTime.toNumber() * 1000, "DD/MM/YYYY HH:mm")} UTC`,
                endTime: `${msToUTCString(endTime.toNumber() * 1000, "DD/MM/YYYY HH:mm")} UTC`,
            })
        } catch (error) {
        }
        setLoading(false);
    }, [library]);

    useEffect(() => {
        loadTimeActivePool();
    }, [library, account, loadTimeActivePool]);

    useEffect(() => {
        allowance();
    }, [library, account, allowance]);

    return {
        isLoading,
        isApprove,
        approve,
        allowance,
        ...timeActivePool
    }

}