export enum EConnectorId {
    Injected = "injected",
    WalletConnect = "walletconnect",
}

export enum EChainLocalKey {
    connectorId = "connectorId",
    chainCode = "chainCode",
    walletTitle = "walletTitle",
    accountActive = "accountActive"
}