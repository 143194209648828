import { useCallback, useState, } from 'react'
import {
  Box,
  Flex,
  HStack,
  Popover,
  Button,
  PopoverTrigger,
  Text,
  Image,
  VStack,
  Icon,
  PopoverContent,
} from '@chakra-ui/react'

import NavLink from './NavLink'
import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  Menu,
  MenuItem,
} from 'react-pro-sidebar'
import ConnectWalletButton from './ConnectWalletButton'
import LogoWeb from '~/assets/svgs/header/logo.png'
import { ReactComponent as MoverseTokenIcon } from '~/assets/svgs/icon-token/moverse.svg'
import { MainLinks } from '~/common/constants/HeaderRouter'
import { useBalanceToken, useConnectWallet, useWalletModal, useWindowSize } from '~/hooks/@global';
import { configEnv } from '~/@config'
import { useNavigate } from 'react-router-dom'
import { routers } from '~/common/constants/Router'
import { ReactComponent as CollapsedIcon } from '~/assets/svgs/collapse-menu-icon.svg'


const { MOVE } = configEnv().ADDRESS_CONFIG;

const Header = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [collapsed, setCollapsed] = useState(false);
  const { login, logout, account } = useConnectWallet()
  const { onPresentAccountModal } = useWalletModal(login, logout, account);
  const { balance } = useBalanceToken({ tokenAddress: MOVE });

  const renderWallet = useCallback(() => {
    if (account) {
      return (
        <Box>
          <Popover trigger={'click'} placement={'bottom-end'}>
            <PopoverTrigger>
              <Button
                p="0px"
                leftIcon={<Icon as={MoverseTokenIcon} h="24px" w="24px" />}
                m="0"
                color="#000"
                bg="transparent"
                _hover={{
                  bg: "transparent"
                }}
                _focus={{
                  bg: "transparent"
                }}
              >
                <Text fontSize="16px" lineHeight="16px" color="#fff">
                  {account?.substring(0, 4) ?? ''}...
                  {account?.substring(account.length - 4) ?? ''}
                </Text>
              </Button>
            </PopoverTrigger>

            <PopoverContent
              border={0}
              boxShadow="0px 4px 6px rgba(222, 222, 222, 0.25) !important"
              background="linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)"
              p="20px"
              rounded="0px 0px 8px 8px"
              w="auto"
            >
              <VStack alignItems="start" spacing={4}>

                <Text
                  onClick={() => {
                    navigate(routers.inventory)
                  }}
                  fontSize="16px"
                  lineHeight="16px"
                  color="#fff"
                >
                  Inventory
                </Text>

                <Text
                  onClick={() => logout()}
                  fontSize="16px"
                  lineHeight="16px"
                  color="#fff"
                >
                  Logout
                </Text>
              </VStack>

            </PopoverContent>

          </Popover>

        </Box>
      )
    }
    return <ConnectWalletButton />
  }, [account, logout, navigate])

  return (
    <Flex overflow="hidden" position="relative">

      <VStack
        bg="rgba(0, 0, 0, 0.9)"
        position="fixed"
        zIndex={99}
        w="100%"
        paddingY="10px"
      >

        <Flex
          h="100%"
          alignItems={'center'}
          justifyContent={'space-between'}
          // maxW="1200px"

          w="96%"
          px={{
            lg: "5px"
          }}
        >
          <Box />

          {account ? (
            <HStack
              spacing={{
                base: 5,
              }}
              background="linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)"
              color="#fff"
              borderRadius="10px"
              // onClick={onPresentAccountModal}
              cursor="pointer"
              p="5px 10px"
            >
              <Text fontSize="16px" lineHeight="16px" color="#fff">
                {balance}
              </Text>
              {renderWallet()}
            </HStack>
          ) : (
            <Box>
              <ConnectWalletButton />
            </Box>
          )}
        </Flex>

      </VStack>

      <Box
        position="fixed"
        zIndex={1000}
        bg="rgba(0, 0, 0, 0.1)"
        h="100vh"
      >
        <Box
          style={{
            transition: 'left 0.3s',
          }}
          position="fixed"
          top="10px"
          left={collapsed ? '68px' : '235px'}
          zIndex="1010"
          display={width <= 768 ? 'none' : 'block'}
          onClick={() => {
            setCollapsed((currentState) => !currentState)
          }}
        >
          <CollapsedIcon />
        </Box>

        <ProSidebar collapsed={collapsed}>

          <SidebarHeader>
            <Box display="flex" justifyContent="center" alignItems="center" pt={collapsed ? "20px" : "0px"}>
              <Image
                src={LogoWeb}
                objectFit="cover"
                w={collapsed ? "auto" : "200px"}
                height={collapsed ? "auto" : "51px"}
              />
            </Box>
          </SidebarHeader>

          <SidebarContent style={{ marginTop: 20, paddingLeft: "15px", paddingRight: "15px" }}>
            <VStack alignItems={"start"} spacing={2}>
              {MainLinks.map((link, idx) => {
                return (
                  <NavLink key={idx} {...link} collapsed={collapsed} />
                )
              })}
            </VStack>

          </SidebarContent>
        </ProSidebar>

      </Box>


    </Flex>
  )
}

export default Header