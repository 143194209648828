import { Button } from '@chakra-ui/react'
import { useConnectWallet, useWalletModal } from '~/hooks/@global'


const ConnectWalletButton = () => {
  const { login } = useConnectWallet()
  const { onPresentConnectModal } = useWalletModal(login)

  return (
    <Button
      minW="120px"
      w="full"
      color="#fff"
      fontSize="16px"
      _focus={{
        backgroundColor: 'linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)',
      }}
      _hover={{
        backgroundColor: 'linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)',
      }}
      background="linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)"
      onClick={onPresentConnectModal}
    >
      Connect Wallet
    </Button>
  )
}

export default ConnectWalletButton
