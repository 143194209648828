import { FC, useState } from 'react'

import {
  Box,
  HStack,
  Link,
  useTheme,
  Popover,
  PopoverTrigger,
  Icon,
  PopoverContent,
} from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'

const NavLink: FC<any> = ({ title, href, subMenu, icon, collapsed }) => {
  const theme = useTheme()
  const [mouseHover, setMouseHover] = useState(false);
  const { pathname } = useLocation()
  const history = useNavigate()

  const isFocused =
    href !== '/' && pathname.includes(href) ? true : pathname === href;

  const onClickMenu = () => {
    if (href) {
      if (href.includes("http")) {
        window.open(href)
      } else {
        history(subMenu && subMenu.length > 0 ? href : href)
      }
    }
  }

  if (collapsed) {
    return (
      <Box
        p="15px"
        cursor={"pointer"}
        onClick={onClickMenu}
      >
        <Icon
          as={icon}
          fontSize="25px"
          color={mouseHover || isFocused ? 'primary' : '#fff'}
          _hover={{
            color: mouseHover || isFocused ? 'primary' : '#fff',
          }}
          display={{
            base: "none",
            xl: "block"
          }}
        />
      </Box>
    )
  }

  return (
    <Box
      key={title}
      onMouseOver={() => {
        setMouseHover(true);
      }}
      onMouseLeave={() => {
        setMouseHover(false);
      }}
    >
      <Popover trigger="hover" placement={'bottom-start'}>
        <PopoverTrigger>
          <HStack position={"relative"}>
            {(mouseHover || isFocused) ?
              <Box w="20px" height={"50px"} background="#FF1CF7" borderRadius={"5px"} ml="5px" />
              : <Box w="20px" height={"50px"} background="transparent" borderRadius={"5px"} />
            }
            <HStack
              position={"absolute"}
              align="center"
              spacing={{
                base: 2,
                lg: 1,
                xl: 1,
              }}
              p="15px 35px"
              borderRadius="10px"
              background={mouseHover || isFocused ? "rgba(43, 14, 78, 0.7)" : "transparent"}
              border={mouseHover || isFocused ? "0.5px solid #FF1CF7" : "none"}
              backdropFilter={mouseHover || isFocused ? "blur(6.5px)" : 'none'}
              w="210px"
              onClick={onClickMenu}
            >
              {icon && (
                <Icon
                  as={icon}
                  fontSize="25px"
                  color={mouseHover || isFocused ? 'primary' : '#fff'}
                  _hover={{
                    color: mouseHover || isFocused ? 'primary' : '#fff',
                  }}
                  display={{
                    base: "none",
                    xl: "block"
                  }}
                />
              )}
              <Link
                onClick={onClickMenu}
                fontWeight={600}
                _hover={{
                  textDecoration: 'none',
                  color: mouseHover || isFocused ? 'primary' : '#fff',
                }}
                color={mouseHover || isFocused ? 'primary' : '#fff'}
                _active={{
                  color: 'primary',
                }}
                fontSize={{
                  base: '18px',
                }}
              >
                {title}
              </Link>
            </HStack>

          </HStack>
        </PopoverTrigger>

        {/* {subMenu && subMenu.length > 0 && (
          <PopoverContent
            border={0}
            boxShadow="0px 0px 14px rgba(186, 186, 186, 0.25)"
            bg={popoverContentBgColor}
            px="20px"
            py="12px"
            rounded="8px"
            w="auto"
            zIndex="1300"
          >
            <Stack zIndex="1300">
              {subMenu.map((child) => {
                if (child?.subMenu) {
                  return (
                    <Box key={child.title}>
                      <DesktopSubNav
                        {...child}
                        isFocused={pathname.includes(child.href)}
                      />
                      {child?.subMenu?.map((chilSub) => {
                        return (
                          <Box key={chilSub.title} ml="32px !important">
                            <DesktopSubNav
                              {...chilSub}
                              isSub
                              isFocused={pathname.includes(chilSub.href)}
                            />
                          </Box>
                        )
                      })}
                    </Box>
                  )
                }

                return (
                  <DesktopSubNav
                    key={child.title}
                    {...child}
                    isFocused={child.href && pathname.includes(child.href)}
                  />
                )
              })}
            </Stack>
          </PopoverContent>
        )} */}
      </Popover>
    </Box >
  )
}

export default NavLink
