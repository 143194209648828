import { Image, Flex, VStack, Text, HStack, Box } from "@chakra-ui/react"
import LogoWeb from '~/assets/svgs/header/logo.png'
import { useWindowSize } from "~/hooks/@global"
import { SocialMoverse } from "./SocialMoverse"
import { SocialsLayout } from "./SocialsLayout"
import TwitterIcon from '~/assets/icons/icon-twitter.svg'
import TeleIcon from '~/assets/icons/icon-tele.svg';

import GitbookIcon from '~/assets/icons/gitbook.svg'
import MailIcon from '~/assets/images/icon_mail.png'

const Footer = () => {
  const { width } = useWindowSize();

  const renderLogo = () => {
    return (
      <VStack alignItems={{ base: "center", md: "start" }}>
        <Image
          src={LogoWeb}
          objectFit="cover"
          height="47px"
          w={"192px"}
        />
        <Text
          fontSize="18px"
          maxW="700px"
          textAlign="left"
          fontWeight="500"
          color="#fff"
        >
          Copyright © 2022 NftFeed Ltd
        </Text>
      </VStack>
    )
  }

  const renderContent = () => {
    return (
      <>
        <SocialMoverse />

        <VStack alignItems={{ base: "center", lg: "start" }}>
          <Text
            fontSize="18px"
            maxW="700px"
            textAlign={{ base: "center", lg: "left" }}
            fontWeight="500"
            color="#fff"
            cursor="pointer"
          >
            Pitchdeck
          </Text>
          <Text
            fontSize="18px"
            maxW="700px"
            textAlign={{ base: "center", lg: "left" }}
            fontWeight="500"
            color="#fff"
            cursor="pointer"
          >
            Whitepaper
          </Text>
        </VStack>

        <VStack alignItems={{ base: "center", lg: "start" }}>
          <Text
            fontSize="18px"
            maxW="700px"
            textAlign={{ base: "center", lg: "left" }}
            fontWeight="500"
            color="#fff"
            cursor="pointer"
          >
            Litepaper
          </Text>
          <Text
            fontSize="18px"
            maxW="700px"
            textAlign={{ base: "center", lg: "left" }}
            fontWeight="500"
            color="#fff"
            cursor="pointer"
          >
            Become influencer
          </Text>
        </VStack>
      </>
    )
  }

  return (
    <VStack w="100%" pb="20vh" spacing={{ base: 10, md: 2 }}>
      <Flex
        h="100%"
        alignItems={'center'}
        justifyContent={'center'}
        maxW="1200px"
        w="full"
      >
        {renderLogo()}

        <SocialsLayout
          socialLinks={[
            {
              id: "1",
              title: "Twitter",
              name: "Twitter",
              icon: TwitterIcon,
              href: "https://twitter.com/NFTFeedOfficial",
            },
            {
              id: "2",
              title: "Telegram",
              name: "Telegram",
              icon: TeleIcon,
              href: "https://t.me/NFTFeedOfficial",
            },
            {
              id: "3",
              title: "Gitbook",
              name: "Gitbook",
              icon: GitbookIcon,
              href: "https://nftfeed.gitbook.io/nftfeed/nft-liquidity/vault-creation",
            },
          ]}
        />
      </Flex>

    </VStack>
  )
}

export default Footer
