import { SimpleGrid, Box, VStack, HStack, Tooltip, Icon, Image, Button, Text } from "@chakra-ui/react";
import { useCallback } from "react";
import { useConnectWallet } from "~/hooks/@global";
import { useBuyNftSwap } from "~/hooks/pool-liq-nft";
import ConnectWalletButton from "~/layouts/ConnectWalletButton";
import { BuyNftItem } from "./BuyNftItem";


const TextInfo = ({ title, value }) => {
    return (
        <>
            <Text
                fontWeight="400"
                fontSize="25px"
                lineHeight="19px"
                color="#fff"
                textAlign="left"
            >
                {title}
            </Text>
            <Text
                fontWeight="400"
                fontSize="25px"
                lineHeight="19px"
                color="primary"
                textAlign="right"
            >
                {value}
            </Text>
        </>
    )
}

export const BuyView = () => {
    const { account } = useConnectWallet();
    const {
        isLoading,
        isApprove,
        priceNft,
        approve,
        listNft,
        checkedNft,
        onChangeCheckBox,
        buy

    } = useBuyNftSwap();


    const renderButton = useCallback(() => {
        if (!account) {
            return <ConnectWalletButton />
        }

        if (!isApprove) {
            return (
                <Button
                    background="linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)"
                    _focus={{
                        backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                    }}
                    _hover={{
                        backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                    }}
                    padding="7.59494px 30.3797px"
                    borderRadius="5px"
                    color="#fff"
                    // w="10%"
                    isLoading={isLoading}
                    onClick={approve}
                >
                    Approve BUSD
                </Button>
            )
        }

        return (
            <Button
                background="linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)"
                _focus={{
                    backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                }}
                _hover={{
                    backgroundColor: "linear-gradient(135deg,#de0245 -16.8%,#fd6e03 138.64%)",
                }}
                padding="7.59494px 30.3797px"
                borderRadius="5px"
                color="#fff"
                // w="10%"
                disabled={checkedNft.size === 0}
                isLoading={isLoading}
                onClick={buy}
            >
                Confirm buy
            </Button>
        )

    }, [account, approve, buy, checkedNft.size, isApprove, isLoading])

    return (
        <div>
            <HStack
                justifyContent="center"
                w="full"
                pb="8px"
            >
                <TextInfo title={"Price: "} value={`${Number(priceNft).toFixed(2)} BUSD`} />
            </HStack>
            <HStack alignItems={"flex-end"} margin="5">
                {renderButton()}


                <HStack
                    justifyContent="flex-end"
                    w="full"
                    pb="8px"
                // borderBottom="0.949367px solid #6B6785"
                >
                    <TextInfo title={"BUSD: "} value={Number(checkedNft.size * priceNft).toFixed(2)} />
                    <TextInfo title={"Selected: "} value={checkedNft.size} />

                </HStack>

            </HStack>
            <SimpleGrid
                columns={{ base: 1, md: 2, xl: 4 }}
                spacing={4}
            >
                {listNft.map((item, idx) => {
                    return (
                        <BuyNftItem

                            tokenId={item.tokenId}
                            checkedNft={checkedNft}
                            onChangeCheckBox={onChangeCheckBox}
                        />
                    )
                })}

            </SimpleGrid>
        </div>
    )
}