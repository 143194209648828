
import { BigNumber, constants, Contract } from "ethers";
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { configEnv } from "~/@config";
import { formatAmountToken } from "~/@helpers/block-chain.helper";
import ERC20__factory from "~/common/abis/ERC20__factory";
import MoverseNft__factory from "~/common/abis/MoverseNft__factory";
import PoolLiqNft__factory from "~/common/abis/PoolLiqNft__factory";
import ChainConfig from "~/common/constants/ChainConfig";
import { useAlert, useConnectWallet } from "../@global";

const { MOVERSE_NFT, POOL_LIQ_NFT, BUSD } = configEnv().ADDRESS_CONFIG;


export const useBuyNftSwap = () => {

    // getAmountOutByNft(
    //     _qty: BigNumberish,
    //     overrides?: CallOverrides
    //   ): Promise<
    //     [BigNumber, BigNumber] & {
    //       amountTokenSwap: BigNumber;
    //       amountTokenValuation: BigNumber;
    //     }
    //   >;

    const toast = useAlert();

    const { library, account } = useConnectWallet();
    const [isLoading, setLoading] = useState(false);
    const [isApprove, setIsApprove] = useState(false);
    const [priceNft, setPriceNft] = useState(0);

    const [listNft, setListNft] = useState<{ tokenId: number }[]>([]);
    const [checkedNft, setCheckedNft] = useState<Set<number>>(new Set<number>([]));

    const loadData = useCallback(async () => {
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const nftCt = new Contract(
                MOVERSE_NFT,
                MoverseNft__factory.abi,
                library
            )
            const list: BigNumber[] = await nftCt.tokenIdsOfOwner(POOL_LIQ_NFT);
            console.log(`-------------------`);
            console.log(list);
            console.log(`-------------------`);
            setListNft(list.map(tokenId => ({
                tokenId: tokenId.toNumber()
            })))
        } catch (error) {
            console.log(`=====loadData=====`, error);
        }
    }, [account, library]);




    const approve = useCallback(async () => {
        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const signer = library.getSigner(account);
            const tokenCt = new Contract(
                BUSD,
                ERC20__factory.abi,
                library
            )
            const { transactionHash } = await (await tokenCt.connect(signer).approve(
                POOL_LIQ_NFT,
                constants.MaxInt256
            )).wait();
            setIsApprove(true);
            toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);
        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
        setLoading(false);
    }, [account, library, toast]);

    const allowance = useCallback(async () => {
        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const tokenCt = new Contract(
                BUSD,
                ERC20__factory.abi,
                library
            )
            const data: BigNumber = await tokenCt.allowance(
                account,
                POOL_LIQ_NFT,
            );
            console.log(`-------------------`);
            console.log({ data });
            console.log(`-------------------`);
            setIsApprove(data.gt(BigNumber.from(0)));
        } catch (error) {
        }
        setLoading(false);


    }, [account, library]);

    const loadPrice = useCallback(async () => {

        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const sender = library.getSigner(account);
            const poolCt = new Contract(
                POOL_LIQ_NFT,
                PoolLiqNft__factory.abi,
                library
            )

            const {
                amountTokenSwap,
                amountTokenValuation
            } = await poolCt.getAmountOutByNft(1);

            setPriceNft(Number(formatAmountToken(amountTokenValuation)))

        } catch (error) {

        }
    }, [account, library]);

    useEffect(() => {
        loadData()
    }, [loadData]);

    useEffect(() => {
        allowance();
    }, [allowance]);

    useEffect(() => {
        loadPrice();
    }, [loadPrice]);



    const onChangeCheckBox = (e: ChangeEvent<HTMLInputElement>, tokenId: number) => {
        const { checked } = e.target;
        if (checked) {
            checkedNft.add(tokenId);
            setCheckedNft(new Set(Array.from(checkedNft)));

        } else {
            checkedNft.delete(tokenId);
            setCheckedNft(new Set(Array.from(checkedNft)));
        }
    }



    const buy = useCallback(async () => {
        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const sender = library.getSigner(account);
            const poolCt = new Contract(
                POOL_LIQ_NFT,
                PoolLiqNft__factory.abi,
                library
            )

            const tokenIds = Array.from(checkedNft);
            const { transactionHash } = await (
                await (poolCt.connect(sender).buy(tokenIds))
            ).wait();

            loadData();
            loadPrice();

            toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);

        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
        setLoading(false);
    }, [account, checkedNft, library, loadData, loadPrice, toast]);

    return {
        isLoading,
        isApprove,
        priceNft,
        approve,
        listNft,
        checkedNft,
        onChangeCheckBox,
        buy
    }
}