
import {
    Box,
    Grid,
    GridItem,
    HStack,
    Icon,
    Image,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom';
import { ReactComponent as VerifyIcon } from '~/assets/svgs/components/verify.svg'
import { ReactComponent as InfoIcon } from '~/assets/svgs/info.svg'
import { ReactComponent as ShieldIcon } from '~/assets/svgs/shield.svg'
import { ReactComponent as PaperIcon } from '~/assets/svgs/paper.svg'
import { ReactComponent as CopyIcon } from '~/assets/svgs/copy.svg'
import { ReactComponent as ArrowDown } from '~/assets/svgs/arrow-down.svg'
import { useNftDetail } from '~/hooks/inventory';
import { pipeAddressUi } from '~/@helpers/format.helper';
import WrapperCopy from '~/components/WrapperCopy';
import { IMG_SHOES } from '~/assets/shoes';



// const data: any = {

// }

type INftDetailViewProps = {
}

const NftDetailView = (props: INftDetailViewProps) => {
    const { tokenId } = useParams();
    const { name, attributes, description, image, address, owner } = useNftDetail({ tokenId });
    return (
        <Box
            p={{
                base: 0,
                lg: '30px',
                xl: '50px',
            }}
            pt={{
                base: '40px',
                lg: '30px',
                xl: '50px',
            }}

            overflow="hidden"
        >
            <Box h={"15vh"} />
            <Grid
                templateRows="repeat(2, 1fr)"
                templateColumns="repeat(8, 1fr)"
                gap={{
                    base: 0,
                    lg: 4,
                }}
            >
                <GridItem
                    rowSpan={2}
                    colSpan={{
                        base: 8,
                        lg: 3,
                    }}
                    p={{
                        base: '20px',
                        lg: '0',
                    }}
                    bg="transparent"
                    borderRadius="8px"
                >
                    <Box position="relative" w="100%" h="100%">
                        <Image
                            w={'full'}
                            h={{
                                base: '374px',
                                lg: '100%',
                            }}
                            borderRadius="8px"
                            src={IMG_SHOES[Number(tokenId) % IMG_SHOES.length]}
                            objectFit={'cover'}
                            fallbackSrc="/assets/images/empty-item.png"
                        />

                    </Box>
                </GridItem>
                <GridItem
                    colSpan={{
                        base: 8,
                        lg: 5,
                    }}
                    p={{
                        base: '0 20px 0 20px',
                        lg: '20px 68px 20px 30px',
                    }}
                    bg="transparent"
                    borderRadius="8px"

                    rowSpan={1}
                >
                    <HStack alignItems="center">
                        <Text
                            fontSize="15px"
                            lineHeight="18px"
                            letterSpacing="-0.5px"
                            color="#fff"
                        >
                            {"NftFeed"}
                        </Text>
                        <Icon as={VerifyIcon} />
                    </HStack>

                    <Text
                        mt="12px"
                        fontSize="24px"
                        lineHeight="28px"
                        fontWeight="600"
                        color="#fff"
                        letterSpacing="-0.5px"
                    >
                        {"Sharkie Genesis"} #{tokenId}
                    </Text>

                </GridItem>

                <GridItem
                    colSpan={{
                        base: 8,
                        lg: 5,
                    }}
                    p={{
                        base: '10px 20px',
                        lg: 0,
                    }}
                    bg={{
                        base: 'white',
                        lg: 'transparent',
                    }}
                    rowSpan={1}
                >
                    <Box
                        border={{
                            base: '1px solid #DFE6F1',
                            lg: 'none',
                        }}
                        borderRadius="8px"
                    >
                        <HStack
                            px={{
                                base: '10px',
                                lg: '30px',
                            }}
                            py="14px"
                            bg="rgba(68, 138, 255, 0.12)"

                            borderRadius="8px 8px 0px 0px"
                        >
                            <Box>
                                <HStack alignItems="center">
                                    <Icon as={InfoIcon} color="#fff" h="24px" w="24px" />
                                    <Text
                                        fontWeight="600"
                                        fontSize="18px"
                                        lineHeight="21px"
                                        color="#fff"
                                        letterSpacing="-0.5px"
                                    >
                                        {"Description "}
                                    </Text>

                                </HStack>
                            </Box>
                            <Spacer />
                            <Box>
                                <Icon
                                    as={ArrowDown}
                                    w="24px"
                                    h="24px"
                                // onClick={() => {
                                //     setIsOpenAboutCollection((current) => !current)
                                // }}
                                />
                            </Box>
                        </HStack>

                        <Box
                            p={{
                                base: '20px 15px 30px 15px',
                                lg: '20px 68px 30px 30px',
                            }}
                            bg="transparent"
                            borderRadius="8px"
                        >
                            <Text
                                fontWeight="400"
                                fontSize="16px"
                                lineHeight="19px"
                                letterSpacing="-0.5px"
                                color="#fff"
                                textAlign="justify"
                            >
                                {description}
                            </Text>
                        </Box>
                    </Box>
                </GridItem>
            </Grid>

            <Grid
                mt={{
                    base: 0,
                    lg: '40px',
                }}
                templateRows="repeat(1, 1fr)"
                templateColumns="repeat(8, 1fr)"
                gap={{
                    base: 0,
                    lg: 4,
                }}
            >
                <GridItem
                    rowSpan={1}
                    colSpan={{
                        base: 8,
                        lg: 3,
                    }}
                    p={{
                        base: '10px 20px',
                        lg: 0,
                    }}
                    bg={{
                        base: 'white',
                        lg: 'transparent',
                    }}
                >
                    <Box
                        border={{
                            base: '1px solid #DFE6F1',
                            lg: 'none',
                        }}
                        borderRadius="8px"
                    >
                        <HStack
                            py="14px"
                            bg="rgba(68, 138, 255, 0.12)"
                            borderRadius="8px 8px 0px 0px"
                            px={{
                                base: '10px',
                                lg: '30px',
                            }}
                        >
                            <HStack>
                                <Icon as={ShieldIcon} h="24px" w="24px" />
                                <Text
                                    fontWeight="600"
                                    fontSize="18px"
                                    lineHeight="21px"
                                    color="#fff"
                                    letterSpacing="-0.5px"
                                >
                                    {"Attributes"}
                                </Text>
                            </HStack>
                            <Spacer />
                            <Box>
                                <Icon
                                    as={ArrowDown}
                                    w="24px"
                                    h="24px"
                                // onClick={() => {
                                //     setIsOpenAttributes((current) => !current)
                                // }}
                                />
                            </Box>
                        </HStack>

                        {
                            (attributes || []).map(({ trait_type: traitType, value }, index) => (
                                <Box w="100%" bg="transparent" borderRadius="8px">
                                    <HStack
                                        px={{
                                            base: '10px',
                                            lg: '30px',
                                        }}
                                        py="20px"
                                    >
                                        <VStack alignItems="flex-start" spacing="20px" w="100%">
                                            <HStack w="100%">
                                                <Text
                                                    fontSize="15px"
                                                    fontWeight="400"
                                                    lineHeight="18px"
                                                    textAlign="justify"
                                                    color="#fff"
                                                >
                                                    {traitType}
                                                </Text>
                                                <Spacer />
                                                <HStack>
                                                    <Text
                                                        fontSize="16px"
                                                        fontWeight="500"
                                                        lineHeight="19px"
                                                        textAlign="justify"
                                                        color="#fff"
                                                    >
                                                        {value}
                                                    </Text>
                                                </HStack>
                                            </HStack>
                                        </VStack>
                                    </HStack>
                                </Box>
                            ))
                        }
                    </Box>
                </GridItem>
                <GridItem
                    colSpan={{
                        base: 8,
                        lg: 5,
                    }}
                    p={{
                        base: '10px 20px',
                        lg: 0,
                    }}
                    bg={{
                        base: 'white',
                        lg: 'transparent',
                    }}
                >
                    <Box
                        border={{
                            base: '1px solid #DFE6F1',
                            lg: 'none',
                        }}
                        borderRadius="8px"
                    >
                        <HStack
                            py="14px"
                            bg="rgba(68, 138, 255, 0.12)"
                            borderRadius="8px 8px 0px 0px"
                            px={{
                                base: '10px',
                                lg: '30px',
                            }}
                        >
                            <HStack>
                                <Icon as={PaperIcon} h="24px" w="24px" />
                                <Text
                                    fontWeight="600"
                                    fontSize="18px"
                                    lineHeight="21px"
                                    color="#fff"
                                    letterSpacing="-0.5px"
                                >
                                    {"NFT info"}
                                </Text>
                            </HStack>
                            <Spacer />
                            <Box>
                                <Icon
                                    as={ArrowDown}
                                    w="24px"
                                    h="24px"
                                // onClick={() => {
                                //     setIsOpenNFTDetail((current) => !current)
                                // }}
                                />
                            </Box>
                        </HStack>

                        <Box
                            w="100%"
                            bg="transparent"
                            px={{
                                base: '10px',
                                lg: '30px',
                            }}
                            py="20px"
                            borderRadius="8px"
                        >
                            <VStack alignItems="flex-start" spacing="20px" w="100%">

                                <HStack w="100%">
                                    <Text
                                        fontSize="15px"
                                        fontWeight="400"
                                        lineHeight="18px"
                                        textAlign="justify"
                                        color="#fff"
                                    >
                                        Token Address
                                    </Text>
                                    <Spacer />
                                    <HStack>
                                        <Text
                                            fontSize="16px"
                                            fontWeight="500"
                                            lineHeight="19px"
                                            textAlign="justify"
                                            color="#fff"
                                        >
                                            {pipeAddressUi(address)}
                                        </Text>
                                        {/* <WrapperCopy copyText={address}>
                                            <Icon as={CopyIcon} />
                                        </WrapperCopy> */}
                                    </HStack>
                                </HStack>
                                <HStack w="100%">
                                    <Text
                                        fontSize="15px"
                                        fontWeight="400"
                                        lineHeight="18px"
                                        textAlign="justify"
                                        color="#fff"
                                    >
                                        Token ID
                                    </Text>
                                    <Spacer />
                                    <HStack>
                                        <Text
                                            fontSize="16px"
                                            fontWeight="500"
                                            lineHeight="19px"
                                            textAlign="justify"
                                            color="#fff"
                                        >
                                            {tokenId}
                                        </Text>
                                    </HStack>
                                </HStack>

                                <HStack w="100%">
                                    <Text
                                        fontSize="15px"
                                        fontWeight="400"
                                        lineHeight="18px"
                                        textAlign="justify"
                                        color="#fff"
                                    >
                                        Owner
                                    </Text>
                                    <Spacer />
                                    <HStack>
                                        <Text
                                            fontSize="16px"
                                            fontWeight="500"
                                            lineHeight="19px"
                                            textAlign="justify"
                                            color="#fff"
                                        >
                                            {pipeAddressUi(owner)}
                                        </Text>

                                    </HStack>
                                </HStack>
                            </VStack>
                        </Box>
                    </Box>
                </GridItem>
            </Grid>

        </Box>
    )
}
export default NftDetailView;