
import { useCallback, useEffect, useState } from "react"
import { configEnv } from "~/@config";
import { IAsk, INftOfCollection } from "~/dto/market.dto";
import nftMarketplaceServices from "~/services/nft-marketplace.services";
import { useAlert, useConnectWallet } from "../@global";


const { ADDRESS_CONFIG } = configEnv();

const { MARKET_ADDRESS, MOVERSE_NFT } = ADDRESS_CONFIG;

export const useMyOrder = () => {

    const toast = useAlert();
    const { library, account } = useConnectWallet();

    const [isLoading, setLoading] = useState(false);



    const [list, setList] = useState<IAsk[]>([]);


    const loadData = useCallback(async () => {
        try {
            const { data } = await nftMarketplaceServices.listAskByUser({
                account,
                pageIndex: 1,
                pageSize: 100,
                valueSearch: ""
            })
            setList(data);
        } catch (error) {

        }
    }, [account])


    useEffect(() => { loadData() }, [loadData]);

    return {
        isLoading,
        loadData,
        list
    }
}